import { poliza } from "./poliza";
import { usuario } from "./usuario";

export class historialPrimaNeta {
    id?: number;
    polizaId?: number;
    poliza?: poliza;
    primaNeta?: number;
    validoDesde?: Date;
    validoHasta?: Date;
    razonCambio?: string;
    fechaCambio?: Date;
    creadoPorId?: number;
    creadoPor?: usuario;
    referenciaPago?: string;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    constructor(){
        this.id = 0;
        this.polizaId = 0;
        this.primaNeta = 0;
        this.validoDesde = new Date();
        this.validoHasta = new Date();
        this.razonCambio = "";
        this.fechaCambio = new Date();
        this.referenciaPago = "";

        this.poliza = new poliza();
        this.creadoPor = new usuario();
    }
}