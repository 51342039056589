export class dashboardResponse {
    mes: string;
    gastosMedicos: dashboardData;
    vida: dashboardData;
    agentesSinSolicitudes: Array<string>;

    constructor(){
        this.mes = "";
        this.gastosMedicos = new dashboardData();
        this.vida = new dashboardData();
        this.agentesSinSolicitudes = new Array<string>();
    }
}

export class dashboardData {
    solicitudesResumen: solicitudesResumen;
    pagado: pagadoAlDia;
    solicitudes: Array<solicitudes>;

    constructor() {
        this.solicitudesResumen = new solicitudesResumen();
        this.pagado = new pagadoAlDia();
        this.solicitudes = new Array<solicitudes>();
    }
}

export class solicitudes{
    nombre: string;
    emitidas: number;
    pendientes: number;
    suspendidas: number;
    rechazadas: number;
    total: number;

    constructor(){
        this.nombre = '';
        this.emitidas = 0;
        this.pendientes = 0;
        this.suspendidas = 0;
        this.rechazadas = 0;
        this.total = 0;
    }
}

export class solicitudesResumen {
    ingresados: number;
    emitidos: number;
    pendientes: number;
    suspendidos: number;
    rechazados: number;

    constructor(){
        this.ingresados = 0;
        this.emitidos = 0;
        this.pendientes = 0;
        this.suspendidos = 0;
        this.rechazados = 0;
    }
}

export class pagadoAlDia {
    month: string;
    quarter: string;
    year: string;

    totalMonth: number;
    totalQuarter: number;
    totalYear: number;

    constructor(){
        this.month = "";
        this.quarter = "";
        this.year = "";

        this.totalMonth = 0;
        this.totalQuarter = 0;
        this.totalYear = 0;
    }
}