import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router, private authService: AuthService) {
    if(this.authService.idprofile != 0){
        switch(this.authService.idprofile){
          case 1:
            this.router.navigate(['admin']);
            break;
          case 2:
              this.router.navigate(['capturista']);
              break;
          case 3:
              this.router.navigate(['agente']);
              break;
        }
    }
}
}
