export class formato {
    id?: number;
    nombre?: string;
    url?: string;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    downloading?: boolean;

    constructor(){
        this.id = 0;
        this.nombre = null;
        this.url = null;
        this.downloading = false;
    }
}