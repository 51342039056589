import { agente } from "./agente";
import { usuario } from "./usuario";
import { estado } from './estado';

export class cliente {
    id?: number;
    agenteId: number;
    fechaNacimiento: Date;
    clienteDesde: string;
    nombre: string;
    sexo?: string;
    rfc: string;
    curp: string;
    email: string;
    celular: string;
    telefono: string;
    calle: string;
    numeroExterior: number;
    numeroInterior: string;
    colonia: string;
    municipio: string;
    estadoId: number;
    codigoPostal: string;
    fechaExpiracion: Date;
    creadoPorId: number;
    numeroClienteAseguradora: string;
    foto: string;
    createdAt: Date;
    updatedAt: Date;
    esContratante: boolean;
    esAsegurado: boolean;
    edad?: number;

    agente?: agente;
    estado?: estado;
    creadoPor?: usuario;

    constructor(){
        // this.agente = new agente();
        // this.estado = new estado();
        // this.creadoPor = new usuario();
        this.sexo = null;
        this.clienteDesde = "N/A";
        this.numeroClienteAseguradora = "";
        this.esContratante = true;
    }
}