import { NgModule, CUSTOM_ELEMENTS_SCHEMA, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AgentesListComponent } from './agentes/agentes-list/agentes-list.component';
import { AgentesDetailComponent } from './agentes/agentes-detail/agentes-detail.component';
import { FormsModule, ReactiveFormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadModule, NgbPaginationModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { ArchivosListComponent } from './archivos/archivos-list/archivos-list.component';
import { ArchivosDialogComponent } from './archivos/archivos-dialog/archivos-dialog.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UsuariosListComponent } from './usuarios/usuarios-list/usuarios-list.component';
import { UsuarioDetailsComponent } from './usuarios/usuario-details/usuario-details.component';
import { ModalConfirmComponent } from './modals/modals.component';
import { ClientesListComponent } from './clientes/clientes-list/clientes-list.component';
import { ClienteDetailsComponent } from './clientes/cliente-details/cliente-details.component';
import { PolizasListComponent } from './polizas/polizas-list/polizas-list.component';
import { PolizaDetailsComponent } from './polizas/poliza-details/poliza-details.component';
import { SiniestrosListComponent } from './siniestros/siniestros-list/siniestros-list.component';
import { SiniestroDetailsComponent } from './siniestros/siniestro-details/siniestro-details.component';
import { TramitesAgenteListComponent } from './tramites/tramites-agente-list/tramites-agente-list.component';
import { TramitesClienteListComponent } from './tramites/tramites-cliente-list/tramites-cliente-list.component';
import { TramitesPolizaListComponent } from './tramites/tramites-poliza-list/tramites-poliza-list.component';
import { TramitesSiniestroListComponent } from './tramites/tramites-siniestro-list/tramites-siniestro-list.component';
import { TramiteDetailsComponent } from './tramites/tramite-details/tramite-details.component';
import { AppDateAdapter, APP_DATE_FORMATS } from 'app/shared/format-datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FilterPipe } from 'app/shared/pipes/filter.pipe';
import { SortByPipe } from 'app/shared/pipes/orderBy.pipe';
import { HistorialPrimaNetaComponent } from './historial-prima-neta/historial-prima-neta/historial-prima-neta.component';
import { HistorialPrimaNetaDetailsComponent } from './historial-prima-neta/historial-prima-neta-details/historial-prima-neta-details.component';
import { HistorialPagosPolizaListComponent } from './historial-pagos-poliza/historial-pagos-poliza-list/historial-pagos-poliza-list.component';
import { CargaPagosPolizaComponent } from './historial-pagos-poliza/carga-pagos-poliza/carga-pagos-poliza.component';
import { UppercaseDirective } from 'app/shared/directives/uppercase';
import { SolicitudesComponent } from './dashboard/solicitudes/solicitudes.component';
import { PagadoAlDiaComponent } from './dashboard/pagado-al-dia/pagado-al-dia.component';
import { SolicitudesResumenComponent } from './dashboard/solicitudes-resumen/solicitudes-resumen.component';
import { SinSolicitudesComponent } from './dashboard/sin-solicitudes/sin-solicitudes.component';
import { FormatosDetailComponent } from './formatos/formatos-detail/formatos-detail.component';
import { FormatosListComponent } from './formatos/formatos-list/formatos-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CumpleanosListComponent } from './clientes/cumpleanos-list/cumpleanos-list.component';
import { MigrateFilesComponent } from './migrate-files/migrate-files.component';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ",",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbTypeaheadModule,
    NgbPaginationModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatAutocompleteModule,
    FileUploadModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    NgxMaskModule.forRoot(options),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AgentesListComponent,
    AgentesDetailComponent,
    ArchivosListComponent,
    ArchivosDialogComponent,
    UsuariosListComponent,
    UsuarioDetailsComponent,
    ModalConfirmComponent,
    ClientesListComponent,
    ClienteDetailsComponent,
    PolizasListComponent,
    PolizaDetailsComponent,
    SiniestrosListComponent,
    SiniestroDetailsComponent,
    TramitesAgenteListComponent,
    TramitesClienteListComponent,
    TramitesPolizaListComponent,
    TramitesSiniestroListComponent,
    TramiteDetailsComponent,
    FilterPipe,
    SortByPipe,
    HistorialPrimaNetaComponent,
    HistorialPrimaNetaDetailsComponent,
    HistorialPagosPolizaListComponent,
    CargaPagosPolizaComponent,
    UppercaseDirective,
    SolicitudesComponent,
    PagadoAlDiaComponent,
    SolicitudesResumenComponent,
    SinSolicitudesComponent,
    FormatosListComponent,
    FormatosDetailComponent,
    CumpleanosListComponent,
    MigrateFilesComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AgentesListComponent,
    AgentesDetailComponent,
    ArchivosListComponent,
    UsuariosListComponent,
    UsuarioDetailsComponent,
    ClientesListComponent,
    ClienteDetailsComponent,
    PolizasListComponent,
    PolizaDetailsComponent,
    SiniestrosListComponent,
    SiniestroDetailsComponent,
    TramitesAgenteListComponent,
    TramitesClienteListComponent,
    TramitesPolizaListComponent,
    TramitesSiniestroListComponent,
    TramiteDetailsComponent,
    HistorialPrimaNetaComponent,
    HistorialPrimaNetaDetailsComponent,
    HistorialPagosPolizaListComponent,
    CargaPagosPolizaComponent,
    SolicitudesComponent,
    PagadoAlDiaComponent,
    SolicitudesResumenComponent,
    SinSolicitudesComponent,
    FormatosListComponent,
    FormatosDetailComponent,
    CumpleanosListComponent,
    MigrateFilesComponent
  ],
  providers: [
    NgbActiveModal,
    //{provide: DateAdapter, useClass: MomentDateAdapter },
    //{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class ComponentsModule { }
