import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'app/shared/services/user.service';
import { usuario } from 'app/shared/models/usuario';

import { showNotification } from 'app/shared/factories/notifications';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';

@Component({
  selector: 'component-usuarios-list',
  templateUrl: './usuarios-list.component.html',
  styleUrls: ['./usuarios-list.component.css']
})
export class UsuariosListComponent implements OnInit {

  @Input() fromModal: boolean;

  usuarios: usuario[];
  search: string;
  pagination: any;
  isWorking: boolean;

  constructor(public usuarioService: UserService, public modal: NgbModal, public activeModal: NgbActiveModal) { 

    this.usuarios = [];
    this.search = '';
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'id_desc',
      pageSize: 50
    };
  }

  ngOnInit(): void {
    this.paginate(this.pagination.page);
  }

  paginate(page){
    this.toggleWorking();
    
    this.usuarioService.get(this.search, this.pagination.order, page, this.fromModal).subscribe(resp => {
      console.log('GET USUARIOS', resp);
      if(resp.status){
        this.usuarios = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GET USUARIOS', err);
      showNotification('Ocurrió un error inesperado', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  checkDelete(usuario){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(usuario);
      }
    }, reject => {});
  }

  delete(usuario){
    this.usuarioService.delete(usuario.id).subscribe(resp => {
      console.log('DELETE USUARIO', resp);
      if(resp.status){
        this.paginate(1);
        showNotification('Usuario eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING USUSARIO', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

}
