import { Component, OnInit, Input, Inject } from '@angular/core';
import { ArchivosService } from 'app/shared/services/archivos.service';
import { archivo } from 'app/shared/models/archivo';
import { showNotification } from 'app/shared/factories/notifications';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArchivosDialogComponent } from '../archivos-dialog/archivos-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'component-archivos-list',
  templateUrl: './archivos-list.component.html',
  styleUrls: ['./archivos-list.component.css']
})
export class ArchivosListComponent implements OnInit {

  @Input() type: string;
  @Input() id: number;
  @Input() title?: string;

  archivos: Array<archivo>;
  fileUrl: string;
  allowModify: boolean;
  isWorking: boolean = false;

  constructor(public archivosService: ArchivosService, public authService: AuthService, @Inject('FILE_URL') file_url: string, public modal: NgbModal) { 
    this.archivos = new Array<archivo>();
    this.fileUrl = file_url;

    this.allowModify = this.authService.idagente == 0;
  }

  ngOnInit(): void {
    if(this.title == null){
      this.title = "Archivos";
    }
    
    console.log(this.type);
    console.log(this.id);
    console.log(this.title);

    this.getArchivos(this.id, this.type);
  }

  getArchivos(id: number, type: string){
    this.archivosService.get(id, type).subscribe(resp => {
      console.log('GET ARCHIVOS', resp);
      if(resp.status){
        this.archivos = resp.data;
        this.archivos.map(a => a['downloading'] = false);
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING ARCHIVOS', err);
      showNotification('Ocurrió un error inesperado al cargar los archivos, por favor intentalo de nuevo.', 'danger');
    });
  }

  checkDelete(archivo){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(archivo);
      }
    }, reject => {});
  }

  delete(archivo){
    this.archivosService.delete(archivo.id).subscribe(resp => {
      console.log('DELETE ARCHIVO', resp);
      if(resp.status){
        this.getArchivos(this.id, this.type);
        showNotification('Archivo eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING ARCHIVO', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  openDialog(){
    const modal = this.modal.open(ArchivosDialogComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.id = this.id;
    modal.componentInstance.type = this.type;
    modal.result.then(result => {
      this.getArchivos(this.id, this.type);
    }, reject => {
      this.getArchivos(this.id, this.type);
    });
  }

  openFile(file: archivo){
    console.log(file.url);
    file['downloading'] = true;
    this.archivosService.download(file.url).subscribe({
      next: ({ filename, data }) => {
        // Create a URL for the blob
        // const fileURL = window.URL.createObjectURL(data);
        // // Create an anchor tag to download the blob with the extracted filename
        // const a = document.createElement('a');
        // document.body.appendChild(a); // Required for Firefox
        // a.style.display = 'none';
        // a.href = fileURL;
        // a.download = filename;
        // a.click();
        // window.URL.revokeObjectURL(fileURL);
        // a.remove();
        // file['downloading'] = false;

        let mimeType = this.getMimeType(filename);
        let binaryData = [];
        binaryData.push(data);
        let downloadLink = document.createElement("a");
        if(mimeType == 'application/octet-stream'){
          downloadLink.download = filename;
        }
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: mimeType }));
        downloadLink.target = "_blank";
        document.body.appendChild(downloadLink);
        downloadLink.click();

        file['downloading'] = false;
      }, 
      error: () =>{
        error => console.error('Download error:', error);
        showNotification('No se pudo descargar el archivo, es posible que este archivo no exista', 'error');
        file['downloading'] = false;
      } 
    });
    
    // window.open(file);
    // this.archivosService.download(file).subscribe(resp => {
    //   console.log(resp);
    //   let fileURL = window.URL.createObjectURL(resp);
    //     window.open(fileURL);
    // })
  } 

  getMimeType(fileName) {
    const extensionToMimeTypeMap = {
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'svg': 'image/svg+xml',
      'webp': 'image/webp',
      'mp3': 'audio/mpeg',
      'wav': 'audio/wav',
      'mp4': 'video/mp4',
      'avi': 'video/x-msvideo',
      'pdf': 'application/pdf',
      'doc': 'application/msword',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls': 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'ppt': 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'txt': 'text/plain',
      'html': 'text/html',
      'css': 'text/css',
      'js': 'application/javascript',
      'json': 'application/json'
    };
  
    const extension = fileName.split('.').pop().toLowerCase();

    console.log(extension);
  
    return extensionToMimeTypeMap[extension] || 'application/octet-stream'; // Default MIME type for unknown extensions
  }

}