import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SortDirection } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class PolizasService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(search: string, finicio: Date, ffin: Date, ramoId: number, estatusId: number, sort: string, order: SortDirection, page: number, agenteId?: number, clienteId?: number): Observable<any>{
    return this.http.get(this.base_url.concat('polizas?', 
    'search=', search, 
    // '&order=', order.toString(), 
    '&page=', (page+1).toString(), 
    '&agenteId=', agenteId != undefined ? agenteId.toString() : '0', 
    '&clienteId=', clienteId != undefined ? clienteId.toString() : '0',
    '&finicio=', finicio.toDateString(),
    '&ffin=', ffin.toDateString(),
    '&ramoId=', ramoId.toString(),
    '&estatusId=', estatusId.toString(),
    '&sort=', sort,
    '&order=', order), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getById(id): Observable<any>{
    return this.http.get(this.base_url.concat('polizas/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(poliza): Observable<any>{
    poliza.montoPoliza = poliza.montoPoliza != '' ? poliza.montoPoliza*1 : null;
    poliza.coaseguro = poliza.coaseguro != '' ? poliza.coaseguro*1 : null;
    poliza.deducible = poliza.deducible != '' ? poliza.deducible*1 : null;
    poliza.agenteId = poliza.agenteId != 0 ? poliza.agenteId : null;
    poliza.clienteId = poliza.clienteId != 0 ? poliza.clienteId : null;
    poliza.participacion = poliza.participacion != '' ? poliza.participacion*1 : null;
    poliza.temporalidad = (poliza.temporalidad != '' && poliza.temporalidad != null) ? poliza.temporalidad*1 : null;

    return this.http.post(this.base_url.concat('polizas'), poliza, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('polizas/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }
}
