import { Routes } from '@angular/router'; 

export const PUBLIC_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'public',
        pathMatch: 'full'
    },
    {
        path: 'public',
        loadChildren: './pages/public/public.module#PublicModule'
    }
];