import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { showNotification } from 'app/shared/factories/notifications';
import { cliente } from 'app/shared/models/cliente';
import { ClientesService } from 'app/shared/services/clientes.service';

@Component({
  selector: 'app-cumpleanos-list',
  templateUrl: './cumpleanos-list.component.html',
  styleUrls: ['./cumpleanos-list.component.css']
})
export class CumpleanosListComponent implements OnInit {

  // @Input() agenteId: number;
  clientes: cliente[];
  search: string;
  pagination: any;
  isWorking: boolean;
  today: Date = new Date();
  month: number;
  months: Array<{ sMonth, iMonth}>;


  constructor(public clientesService: ClientesService) { 
    
    this.months = new Array<{ sMonth: string, iMonth: number}>();
    this.months.push({ sMonth: "ENERO", iMonth: 1});
    this.months.push({ sMonth: "FEBRERO", iMonth: 2});
    this.months.push({ sMonth: "MARZO", iMonth: 3});
    this.months.push({ sMonth: "ABRIL", iMonth: 4});
    this.months.push({ sMonth: "MAYO", iMonth: 5});
    this.months.push({ sMonth: "JUNIO", iMonth: 6});
    this.months.push({ sMonth: "JULIO", iMonth: 7});
    this.months.push({ sMonth: "AGOSTO", iMonth: 8});
    this.months.push({ sMonth: "SEPTIEMBRE", iMonth: 9});
    this.months.push({ sMonth: "OCTUBRE", iMonth: 10});
    this.months.push({ sMonth: "NOVIEMBRE", iMonth: 11});
    this.months.push({ sMonth: "DICIEMBRE", iMonth: 12});


    this.clientes = [];
    this.search = '';
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'nombre',
      pageSize: 50
    };

    this.month = this.today.getMonth() + 1;
    
  }

  ngOnInit(): void {
    this.paginate();
  }

  paginate(){
    this.toggleWorking();
    
    this.clientesService.getCumples(this.month).subscribe(resp => {
      console.log('GET CLIENTES', resp);
      if(resp.status){
        this.clientes = resp.data;
        this.clientes.forEach(c => {
          c.edad = this.today.getFullYear() - new Date(c.fechaNacimiento).getFullYear();
        });
        // this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GET CLIENTES', err);
      showNotification('Ocurrió un error inesperado', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  // searchBox = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map(term => this.paginate(1))
  //   )

}
