import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistorialPagosPolizaService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(search: string, page: number, polizaId?: number): Observable<any>{
    return this.http.get(this.base_url.concat('historialpagospoliza?', 'search=', search, '&page=', page.toString(), '&polizaId=', polizaId != undefined ? polizaId.toString() : '0'), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getById(id): Observable<any>{
    return this.http.get(this.base_url.concat('historialpagospoliza/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(historial): Observable<any>{
    historial.polizaId = historial.polizaId != 0 ? historial.polizaId : null;
    return this.http.post(this.base_url.concat('historialpagospoliza'), historial, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('historialpagospoliza/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }
}
