import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'component-sin-solicitudes',
  templateUrl: './sin-solicitudes.component.html',
  styleUrls: ['./sin-solicitudes.component.css']
})
export class SinSolicitudesComponent implements OnInit {

  @Input('mes') mes: string;
  @Input('data') agentesSinSolicitudes: Array<string>;

  constructor() { 
    this.mes = '';
    this.agentesSinSolicitudes = new Array<string>();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.agentesSinSolicitudes = changes.agentesSinSolicitudes.currentValue;

    console.log("AGENTES SIN SOLICITUDES", this.agentesSinSolicitudes);
  }

}
