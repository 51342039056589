import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { solicitudes } from 'app/shared/models/dashboard';

@Component({
  selector: 'component-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css']
})
export class SolicitudesComponent implements OnInit {

  @Input('mes') mes: string;
  @Input('ramo') ramo: string;
  @Input('solicitudes') solicitudes: solicitudes;

  constructor() { 
    this.mes = "";
    this.ramo = "";
    this.solicitudes = new solicitudes();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.solicitudes = changes.solicitudes.currentValue;

    console.log('SOLICITUDES', this.ramo, this.solicitudes);
  }

}