import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { PUBLIC_ROUTES } from './pages/public/public.routes';
import { ADMIN_ROUTES } from './pages/admin/admin.routes';
import { CAPTURISTA_ROUTES } from './pages/capturista/capturista.routes';
import { AGENTE_ROUTES } from './pages/agente/agente.routes';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';

import { AuthGuard } from './shared/services/guards/auth.guard';

import { AuthCapturistaGuard } from './shared/services/guards/auth-capturista.guard';
import { AuthAgenteGuard } from './shared/services/guards/auth-agente.guard';

const routes: Routes =[
  { path: '', component: PublicLayoutComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES },
  { path: '', component: AdminLayoutComponent, data: { title: 'Admin Views' }, children: ADMIN_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: AdminLayoutComponent, data: { title: 'Capturista Views' }, children: CAPTURISTA_ROUTES, canActivate: [AuthCapturistaGuard] },
  { path: '', component: AdminLayoutComponent, data: { title: 'Agente Views' }, children: AGENTE_ROUTES, canActivate: [AuthAgenteGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
