import { Component, OnInit, Inject, Input } from '@angular/core';
import { usuario } from 'app/shared/models/usuario';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/shared/services/user.service';
import { AuthService } from 'app/shared/services/auth.service';
import { showNotification } from 'app/shared/factories/notifications';
import { PerfilesService } from 'app/shared/services/perfiles.service';
import { perfil } from 'app/shared/models/perfil';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'component-usuario-details',
  templateUrl: './usuario-details.component.html',
  styleUrls: ['./usuario-details.component.css']
})
export class UsuarioDetailsComponent implements OnInit {

  @Input() id: string;
  @Input() usuario: usuario;
  perfiles: Array<perfil>;
  isWorking: boolean;
  showPass: boolean;
  exists: boolean;
  userName: string;

  constructor(public usuarioService: UserService, 
      public perfilesService: PerfilesService,
      public router: Router,
      public authService: AuthService,
      public activeModal: NgbActiveModal) { 

        this.showPass = false;
        this.isWorking = false;
        this.exists = false;

        this.perfiles = new Array<perfil>();
  }

  ngOnInit(): void {
    this.showPass = (this.id != undefined && this.id != '0') ? false : true;

    if(this.id != undefined && this.id != '0'){
      this.getUsuario(this.id);
    }else{
      if(this.usuario == undefined){
        this.usuario = new usuario(); 
      }
    }
    
    this.getPerfiles();
  }

  getUsuario(id, cllbck?){
    this.toggleWorking();

    this.usuarioService.getById(id).subscribe(resp => {
      console.log('GET USUARIO', resp);
      if(resp.status){
        this.usuario = resp.data;
        if(cllbck){
          cllbck(resp.data);
        }
        this.userName = this.usuario.usuario.valueOf();
        this.showPass = false;
      }else{
        showNotification(resp.message, 'danger');
        this.router.navigate(['admin','usuarios']);
      }
    }, err => {
      console.error('GETTING USUARIO', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo', 'danger');
      this.router.navigate(['admin','usuarios']);
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  getPerfiles(){
    this.perfilesService.get().subscribe(resp => {
      console.log('GET PERFILES', resp);
      if(resp.status){
        this.perfiles = resp.data;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING PERFILES', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    })
  }

  save(usuario){
    console.log('USUARIO TO SAVE', usuario);
    this.toggleWorking();

    this.usuarioService.save(usuario).subscribe(resp => {
      console.log('SAVE USUARIO', resp);
      if(resp.status && this.id == '0'){
        this.id = resp.data;
        this.getUsuario(this.id, (usr) => {
          this.activeModal.close(usr);
        });
      }
      this.showPass = false;
      showNotification(resp.message, resp.status ? 'success' : 'danger');
    }, err => {
      console.error('ERROR SAVING USUARIO', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
      this.toggleWorking();
      this.showPass = false;
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  changePass(){
    this.showPass = !this.showPass;
  }
  
  checkUserExists(){
    if(this.usuario.usuario.length > 3){
      this.usuarioService.checkUserExists(this.usuario.usuario).subscribe(resp => {
        console.log('USER EXISTS', resp);
        this.exists = resp.data;
      }, err => {
        console.error('USER EXIST', err);
        showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
      });
    }
  }

  checkUserName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.checkUserExists())
    )

}
