import { Component, OnInit } from '@angular/core';
import { ArchivosService } from 'app/shared/services/archivos.service';

@Component({
  selector: 'app-migrate-files',
  templateUrl: './migrate-files.component.html',
  styleUrls: ['./migrate-files.component.css']
})
export class MigrateFilesComponent implements OnInit {

  isWorking = false;

  constructor(private archivosService: ArchivosService) { }

  ngOnInit(): void {
  }

  migrate(){
    this.isWorking = true;
    console.log('Migrando...');
    this.archivosService.migrate().subscribe(resp => {
      console.log(resp);
      this.isWorking = false;
    }, err => {
      console.error(err);
      this.isWorking = false;
    });
  }

}
