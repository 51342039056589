export class colonia {
    id?: number;
    postalCode?: string;
    adminName1?: string;
    adminName2?: string;
    placeName?: string;
    active?: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(){
        this.id = 0;
        this.postalCode = null;
        this.adminName1 = null;
        this.adminName2 = null;
        this.placeName = null;
        this.active = true;
    }
}