import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { colonia } from '../models/colonia';

@Injectable({
  providedIn: 'root'
})
export class GeonamesService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(postalCode = ''): Observable<any>{
    return this.http.get(this.base_url.concat('GeoNames?postalcode=', postalCode), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  save(colonia): Observable<any>{
    return this.http.post(this.base_url.concat('GeoNames'), colonia, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }
}
