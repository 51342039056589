import { usuario } from "./usuario";
import { estado } from "./estado";

export class agente {
    id?: number;
    usuarioId: number;
    estadoId: number;
    fechaNacimiento: Date;
    miembroDesde: Date;
    nombre: string;
    rfc: string;
    curp: string;
    email: string;
    celular: string;
    telefono: string;
    calle: string;
    numeroExterior: number;
    numeroInterior: string;
    colonia: string;
    municipio: string;
    codigoPostal: string;
    cedula: string;
    expiracionCedula: Date;
    creadoPorId: number;
    claveAgente: string;
    foto: string;
    comentarios: string;
    createdAt: Date;
    updatedAt: Date;

    usuario?: usuario;
    estado?: estado;
    creadoPor?: usuario;

    constructor(){
        // this.usuario = new usuario();
        // this.estado = new estado();
        // this.creadoPor = new usuario();
        this.usuarioId = null;
    }
}