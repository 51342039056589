import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { pagadoAlDia } from 'app/shared/models/dashboard';

@Component({
  selector: 'component-pagado-al-dia',
  templateUrl: './pagado-al-dia.component.html',
  styleUrls: ['./pagado-al-dia.component.css']
})
export class PagadoAlDiaComponent implements OnInit {

  @Input("mes") mes: string;
  @Input("pagado-gm") pagadoGM: pagadoAlDia;
  @Input("pagado-vida") pagadoVida: pagadoAlDia;

  constructor() { 
    this.mes = "";
    this.pagadoGM = new pagadoAlDia();
    this.pagadoVida = new pagadoAlDia();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.pagadoGM = changes.pagadoGM.currentValue;
    this.pagadoVida = changes.pagadoVida.currentValue;

    console.log("PAGADO GM", this.pagadoGM);
    console.log("PAGADO VIDA", this.pagadoVida);
  }

}
