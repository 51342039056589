import { Component, OnInit, Input } from '@angular/core';
import { TramitesService } from 'app/shared/services/tramites.service';
import { showNotification } from 'app/shared/factories/notifications';
import { tramite, tramiteAgente, tipoTramite, fila, estatusTramite, tramiteCliente, tramitePoliza, tramiteSiniestro } from 'app/shared/models/tramite';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SiniestrosService } from 'app/shared/services/siniestros.service';
import { AgentesListComponent } from 'app/components/agentes/agentes-list/agentes-list.component';
import { PolizasListComponent } from 'app/components/polizas/polizas-list/polizas-list.component';
import { ClientesListComponent } from 'app/components/clientes/clientes-list/clientes-list.component';
import { SiniestrosListComponent } from 'app/components/siniestros/siniestros-list/siniestros-list.component';
import { AgentesService } from 'app/shared/services/agentes.service';
import { ClientesService } from 'app/shared/services/clientes.service';
import { PolizasService } from 'app/shared/services/polizas.service';
import { AuthService } from 'app/shared/services/auth.service';
import { PolizaDetailsComponent } from 'app/components/polizas/poliza-details/poliza-details.component';
import { CategoriasTramiteService } from 'app/shared/services/categorias-tramite.service';
import { categoriaTramite } from 'app/shared/models/categoriaTramite';

@Component({
  selector: 'app-tramite-details',
  templateUrl: './tramite-details.component.html',
  styleUrls: ['./tramite-details.component.css']
})
export class TramiteDetailsComponent implements OnInit {

  @Input() type: string;
  @Input() id: number;
  @Input() entityId: number;

  tramite: any;
  tipos: Array<tipoTramite>;
  filas: Array<fila>;
  estatuses: Array<estatusTramite>;

  categoriaId: number = 0;

  isWorking: boolean;
  allowModify: boolean;

  constructor(public tramiteService: TramitesService, 
    public activeModal: NgbActiveModal, 
    public modal: NgbModal, 
    public siniestroService: SiniestrosService,
    public authService: AuthService,
    public agenteService: AgentesService, 
    public clienteService: ClientesService,
    public polizaService: PolizasService) { 
    this.tipos = new Array<tipoTramite>();
    this.filas = new Array<fila>();
    this.isWorking = false;
    // this.allowModify = true; // this.authService.idagente == 0;
    this.allowModify = this.authService.idagente == 0;
    this.categoriaId = 0;
  }

  ngOnInit(): void {
    console.log('ENTITYID', this.entityId);
    switch(this.type){
      case 'agente':
        this.categoriaId = 3;
        this.tramite = new tramiteAgente();
        if(this.entityId != 0){
          this.getAgente(this.entityId);
        }
      break;
      case 'cliente':
        this.tramite = new tramiteCliente();
        if(this.entityId != 0){
          this.getCliente(this.entityId);
        }
      break;
      case 'poliza':
          this.categoriaId = 1;
          this.tramite = new tramitePoliza();
          if(this.entityId != 0){
            this.getPoliza(this.entityId);
          }
      break;
      case 'siniestro':
        this.categoriaId = 4;
        this.tramite = new tramiteSiniestro();
        if(this.entityId != 0){
          this.getSiniestro(this.entityId);
        }
        break;
    }

    if(this.id != 0){
      this.getTramite(this.id);
    }

    this.getTipos();
    this.getFilas();
    this.getEstatus();
  }

  getPoliza(id){
    this.polizaService.getById(id).subscribe(resp => {
      console.log('GET POLIZA', resp);
      if(resp.status){
        this.tramite['poliza'] = resp.data;
        this.tramite['polizaId'] = resp.data.id;
        this.categoriaId = this.tramite.poliza.ramoId == 1 ? 2 : 1;
        this.getTipos();
        this.getFilas();
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING POLIZA', err);
      showNotification('Ocurrió un error al obtener el POLIZA, intentalo de nuevo', 'warning');
    });
  }

  getCliente(id){
    this.clienteService.getById(id).subscribe(resp => {
      console.log('GET CLIENTe', resp);
      if(resp.status){
        this.tramite['cliente'] = resp.data;
        this.tramite['clienteId'] = resp.data.id;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING CLIENTe', err);
      showNotification('Ocurrió un error al obtener el Cliente, intentalo de nuevo', 'warning');
    });
  }

  getAgente(id){
    this.agenteService.getById(id).subscribe(resp => {
      console.log('GET AGENTE', resp);
      if(resp.status){
        this.tramite['agente'] = resp.data;
        this.tramite['agenteId'] = resp.data.id;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING AGENTE', err);
      showNotification('Ocurrió un error al obtener el Agente, intentalo de nuevo', 'warning');
    });
  }

  getSiniestro(id){
    this.siniestroService.getById(id).subscribe(resp => {
      console.log('GET SINIESTRO', resp);
      if(resp.status){
        this.tramite['siniestroPoliza'] = resp.data;
        this.tramite['siniestroPolizaId'] = resp.data.id;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING SINIESTRO', err);
      showNotification('Ocurrió un error al obtener el Siniestro, intentalo de nuevo', 'warning');
    });
  }

  getTramite(id){
    this.tramiteService.getById(id, this.type).subscribe(resp => {
      console.log('GET TRAMITE', resp);
      if(resp.status){
        this.tramite = resp.data;
        this.categoriaId = this.tramite.poliza.ramoId == 1 ? 2 : 1;
        this.getTipos();
        this.getFilas();
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING TRAMITE', err);
      showNotification('Ocurrió un error inesperado, verifica tu conexión a internet.', 'danger');
    });
  }

  getTipos(){
    this.tramiteService.getTipos(this.categoriaId).subscribe(resp => {
      console.log('GET TIPOS TRAMITE', resp);
      if(resp.status){
        this.tipos = resp.data;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING TIPOS TRAMITE', err);
      showNotification('Ocurrió un error inesperado, no pudimos obtener los tipos de tramite, verifica tu conexión a internet.', 'warning');
    });
  }

  getFilas(){
    this.tramiteService.getFilas(this.categoriaId).subscribe(resp => {
      console.log('GET FILAS', resp);
      if(resp.status){
        this.filas = resp.data;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING FILAS', err);
      showNotification('Ocurrió un error inesperado, no pudimos obtener las Filas, verifica tu conexión a internet', 'warning');
    });
  }

  getEstatus(){
    this.tramiteService.getEstatus().subscribe(resp => {
      console.log('GET ESTATUS', resp);
      if(resp.status){
        this.estatuses = resp.data;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING ESTATUS', err);
      showNotification('Ocurrió un error inesperado, no pudimos cargar los Estatus, verifica tu conexión a internet', 'warning');
    });
  }

  save(tramite){
    console.log('TRAMITE TO SAVE', tramite);
    this.toggleWorking();

    if(this.type != 'siniestro'){
      this.tramiteService.save(tramite, this.entityId, this.type).subscribe(resp => {
        console.log('SAVE TRAMITE', resp);
        if(resp.status && this.id == 0){
          this.id = resp.data;
          this.getTramite(this.id);
        }
        this.activeModal.close();
        showNotification(resp.message, resp.status ? 'success' : 'danger');
      }, err => {
        console.error('ERROR SAVING TRAMITE', err);
        showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
        this.toggleWorking();
      }, () => {
        this.toggleWorking();
      });
    }else{
      this.tramiteService.saveTramiteSiniestro(tramite).subscribe(resp => {
        console.log('SAVE TRAMITE', resp);
        if(resp.status && this.id == 0){
          this.id = resp.data;
          this.getTramite(this.id);
        }
        this.activeModal.close();
        showNotification(resp.message, resp.status ? 'success' : 'danger');
        this.toggleWorking();
      }, err => {
        console.error('ERROR SAVING TRAMITE', err);
        showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
        this.toggleWorking();
      });
    }
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  openAgentes(){
    const modal = this.modal.open(AgentesListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.tramite.agente = result;
        this.tramite.agenteId = result.id;
        this.entityId = result.id;
      }
    }, reject => {});
  }

  openPolizas(){
    const modal = this.modal.open(PolizasListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.tramite.poliza = result;
        this.tramite.polizaId = result.id;
        this.entityId = result.id;
        this.categoriaId = this.tramite.poliza.ramoId == 1 ? 2 : 1;
        this.getTipos();
        this.getFilas();
      }
    }, reject => {});
  }

  openPoliza(){
    const modal = this.modal.open(PolizaDetailsComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.componentInstance.idPoliza = this.tramite.polizaId;
    modal.result.then(result => {
      if(result){
        this.tramite.poliza = result;
        this.tramite.polizaId = result.id;
        this.entityId = result.id;
      }
    }, reject => {});
  }

  openClientes(){
    const modal = this.modal.open(ClientesListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpaddin'});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.tramite.cliente = result;
        this.tramite.clienteId = result.id;
        this.entityId = result.id;
      }
    }, reject => {});
  }

  openSiniestros(){
    const modal = this.modal.open(SiniestrosListComponent, {size: 'xl', centered: true, container: 'div', windowClass: ''});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.tramite.siniestroPoliza = result;
        this.tramite.siniestroPolizaId = result.id;
        this.entityId = result.id;
      }
    }, reject => {});
  }

}
