import { Component, OnInit, Input } from '@angular/core';
import { historialPrimaNeta } from 'app/shared/models/historialPrimaNeta';
import { ThrowStmt } from '@angular/compiler';
import { HistorialPrimaNetaService } from 'app/shared/services/historial-prima-neta.service';
import { showNotification } from 'app/shared/factories/notifications';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-historial-prima-neta-details',
  templateUrl: './historial-prima-neta-details.component.html',
  styleUrls: ['./historial-prima-neta-details.component.css']
})
export class HistorialPrimaNetaDetailsComponent implements OnInit {

  @Input() temporalidad: number;
  @Input() polizaId: number;

  historial: historialPrimaNeta;

  constructor(private historialService: HistorialPrimaNetaService, private modal: NgbActiveModal) {
    this.historial = new historialPrimaNeta();
  }

  ngOnInit(): void {
    console.log("SIMON1", this.temporalidad);
    console.log("SIMON2", this.polizaId);

    this.historial.validoHasta = new Date(
      this.historial.validoHasta.getFullYear() + this.temporalidad, 
      this.historial.validoHasta.getMonth(), 
      this.historial.validoHasta.getDate()
    );

    this.historial.polizaId = this.polizaId;
  }

  save(historial){
    this.historialService.save(historial).subscribe((resp) => {
      console.log("SAVING HISTORIAL", resp);
      if(resp.status){
        showNotification("Prima Neta Guardada.", 'success');
      }else{
        showNotification(resp.message, 'error');
      }
      this.modal.close(historial);
    }, err => {
      console.error("ERROR SAVING HISTORIAL", err);
      showNotification("Ocurrió un error al guardar la nueva Prima Neta, por favor intentalo de nuevo.", "error");
      this.modal.close();
    });
  }

}
