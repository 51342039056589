import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { agente } from 'app/shared/models/agente';
import { AgentesService } from 'app/shared/services/agentes.service';
import { showNotification } from 'app/shared/factories/notifications';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'app/shared/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosListComponent } from 'app/components/usuarios/usuarios-list/usuarios-list.component';
import { EstadosService } from 'app/shared/services/estados.service';
import { estado } from 'app/shared/models/estado';
import { usuario } from 'app/shared/models/usuario';
import { UsuarioDetailsComponent } from 'app/components/usuarios/usuario-details/usuario-details.component';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { GeonamesService } from 'app/shared/services/geonames.service';
import { colonia } from 'app/shared/models/colonia';

@Component({
  selector: 'component-agentes-detail',
  templateUrl: './agentes-detail.component.html',
  styleUrls: ['./agentes-detail.component.css']
})
export class AgentesDetailComponent implements OnInit {

  @Input() idAgente: string;
  agente: agente;
  isWorking: boolean;
  estados: Array<estado>;
  baseUrl: string;
  allowModify: boolean = false;

  fileUrl: string;

  private uploader: FileUploader;

  searchColoniaCtrl = new FormControl();
  filteredColonias: any[];
  isLoading = false;
  writed = false;

  constructor(public route: ActivatedRoute, 
    public agenteService: AgentesService,
    public estadoService: EstadosService,
    public GeoNamesService: GeonamesService,
    public modal: NgbModal,
    public router: Router, @Inject('BASE_URL') base_url: string,
      public authService: AuthService, @Inject('FILE_URL') file_url: string) { 
      
        this.baseUrl = base_url;
        this.estados = new Array<estado>();
        this.agente = new agente();
        this.agente.usuario = new usuario();
        this.agente.creadoPor = new usuario();
        this.isWorking = false;

        this.fileUrl = file_url;
  }

  ngOnInit(): void {

    this.allowModify = this.authService.idagente == 0;
    
    if(!this.allowModify){
      this.searchColoniaCtrl.disable(); 
    }

    this.searchColoniaCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredColonias = [];
          this.isLoading = true;
        }),
        switchMap(value => this.GeoNamesService.get(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        console.log('COLONIA SEARCH', data);
        if (data['postalCodes'] == undefined) {
          //this.errorMsg = data['Error'];
          this.filteredColonias = [];
        } else {
          //this.errorMsg = "";
          this.filteredColonias = data['postalCodes'];
        }
 
        console.log(this.filteredColonias);
      });

    if(this.idAgente == undefined){
      this.idAgente = this.route.snapshot.paramMap.get('id');
    }

    console.log('AGENTE REGISTRADO', this.idAgente);

    this.uploader = new FileUploader({
      url: this.baseUrl.concat('/upload?folder=perfil'),
      disableMultipart: false,
      authToken: this.authService.getToken(),
      allowedMimeType: ['image/jpg', 'image/png', 'image/jpeg'],
      itemAlias: 'file',
      isHTML5: true
    });

    this.uploader.onErrorItem = (item, response, status, headers) => {
      console.error("FILE UPLOAD ERROR", response);
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      console.log('FILE UPLOADED', response);
      // this.banner.file = response;
      // this.saveBanner();
    };

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }

    if(this.idAgente != '0'){
      this.getAgente(this.idAgente);
    }

    this.getEstados();
  }

  getEstados(){
    this.estadoService.get().subscribe(resp => {
      console.log('GET ESTADOS', resp);
      if(resp.status){
        this.estados = resp.data;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING ESTADOS', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    })
  }

  selectColonia(colonia){
    console.log('SELECTED COLONIA', colonia);
    
    this.agente.municipio = colonia.adminName2.toUpperCase();
    var estado = this.estados.filter((val, idx) => {
      return val.nombre.toLowerCase() == colonia.adminName1.toLowerCase();
    });

    if(estado != null && estado.length > 0){
      this.agente.estado = estado[0];
      this.agente.estadoId = estado[0].id;
    }

    console.log('ESTADO AUTOMATIC', estado);
    this.writed = false;
  }

  getAgente(id){
    this.toggleWorking();

    this.agenteService.getById(id).subscribe(resp => {
      console.log('GET AGENTE', resp);
      if(resp.status){
        this.agente = resp.data;
        if(this.agente.usuario == null){
          this.agente.usuarioId = 0;
          this.agente.usuario = new usuario();
        }
        this.agente.foto = this.agente.foto ? this.agente.foto : '';
      }else{
        showNotification(resp.message, 'danger');
        this.router.navigate(['admin','agentes']);
      }
    }, err => {
      console.error('GETTING AGENTE', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo', 'danger');
      this.router.navigate(['admin','agentes']);
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  coloniaFocusOut(colonia){
    var col = this.filteredColonias.filter((val, idx) => {
      return val['placeName'] == colonia;
    });

    if(col != null && col.length > 0){
      this.selectColonia(col[0]);
    }

    console.log('WRITEN COLONIA MATCH', col);
  }

  estadoChanged(idEstado){
    var est = this.estados.filter((val, idx) => {
      return val.id == idEstado;
    });

    if(est != null && est.length > 0){
      this.agente.estado = est[0];
    }
  }

  save(agente){
    console.log('AGENTE TO SAVE', agente);
    console.log('WRITED', this.writed);
    this.toggleWorking();

    if(this.writed){
      let col = new colonia();
      col.placeName = this.agente.colonia;
      col.adminName2 = this.agente.municipio;
      col.adminName1 = this.agente.estado.nombre;
      col.active = true;
      col.postalCode = this.agente.codigoPostal;
      
      this.GeoNamesService.save(col).subscribe(resp => {
        console.log('SAVED COLONIA', resp);
        if(resp.status){
          this.filteredColonias.push(resp.data);
          showNotification('Nueva Colonia Guardada', 'success'); 
        }
      }, err => {
        console.error('SAVING COLONIA', err);
      });
    }

    this.agenteService.save(agente).subscribe(resp => {
      console.log('SAVE AGENTE', resp);
      if(resp.status && this.idAgente == '0'){
        this.idAgente = resp.data;
        this.getAgente(this.idAgente);
      }
      showNotification(resp.message, resp.status ? 'success' : 'danger');
    }, err => {
      console.error('ERROR SAVING AGENTE', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  fileSelected(event){
    console.log('SELECTED IMAGE', this.uploader);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.agente.foto = ev.target.result.toString();
        console.log('SELECTED IMAGE AFTER', this.uploader);
      }
    }else{
      this.agente.foto = '';
    }
  }

  openUsuarios(){
    const modal = this.modal.open(UsuarioDetailsComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    let usr = new usuario();
    usr.id = this.agente.usuarioId;
    usr.nombre = this.agente.nombre;
    usr.perfilId = 3;

    modal.componentInstance.fromModal = true;
    modal.componentInstance.id = this.agente.usuarioId;
    modal.componentInstance.usuario = usr;
    modal.result.then(result => {
      if(result){
        this.agente.usuario = result;
        this.agente.usuarioId = result.id;
        this.save(this.agente);
      }
    }, reject => {});
  }

  // savePhoto(){
  //   console.log('Agente TO SAVE', this.agente);
  //   console.log(this.uploader);

  //   if(this.uploader.queue.length > 0){
  //     this.uploader.uploadAll();
  //   }else{
  //     showNotification('Selecciona una imagen', 'warning'); 
  //   }
  // }

}
