import { Component, OnInit, Inject } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'app/shared/services/auth.service';
import { showNotification } from 'app/shared/factories/notifications';
import { cargaPagosResult } from 'app/shared/models/pagosPoliza';
import { IndicadoresCambioService, responseIndicador } from 'app/shared/services/indicadores-cambio.service';

@Component({
  selector: 'component-carga-pagos-poliza',
  templateUrl: './carga-pagos-poliza.component.html',
  styleUrls: ['./carga-pagos-poliza.component.css']
})
export class CargaPagosPolizaComponent implements OnInit {

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  baseUrl: string;
  arrResp: cargaPagosResult[];
  cambioDolares: number = null;

  constructor(private authService: AuthService,
    @Inject('BASE_URL') base_url: string,
    private indicadoresService: IndicadoresCambioService) { 
      this.baseUrl = base_url;
      this.hasBaseDropZoneOver = false;

      this.arrResp = new Array<cargaPagosResult>();
  }

  ngOnInit(): void {
    this.getCambioDolares();

    this.uploader = new FileUploader({
      url: this.baseUrl.concat('historialpagospoliza/carga'),
      disableMultipart: false,
      authToken: this.authService.getToken(),
      allowedFileType: ['xls'],
      // allowedMimeType: ['application/vnd.ms-excel'],
      itemAlias: 'file',
      isHTML5: true
    });

    this.uploader.onErrorItem = (item, response, status, headers) => {
      console.error("FILE UPLOAD ERROR", response);
      showNotification('No se pudo subir el archivo ' + item.file.name + ' intentalo nuevamente o con un archivo más ligero', 'danger');
    };

    this.uploader.onSuccessItem = (item, response: any, status, headers) => {
      console.log('FILE UPLOADED response', response);
      console.log('FILE UPLOADED item', item);
      console.log('FILE UPLOADED status', status);

      let resp = JSON.parse(response);

      if(resp != null && resp.status){
        resp.data.totalProcesados = resp.data.actualizados.length + resp.data.duplicados.length + resp.data.noEncontrados.length;
        this.arrResp.push(resp.data);
        showNotification(item.file.name.concat(' Procesado con Éxito'), 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    };

    this.uploader.onBuildItemForm = (item, form) => {
      // form.append('match', JSON.stringify(this.matchArray));
      // form.append('entity', JSON.stringify(this.data));
    };

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      //item.url.concat('?dolar=', this.cambioDolares.toString());
    }
  }

  getCambioDolares(){
    this.indicadoresService.getCambioDolarByDate(new Date(), new Date()).subscribe((resp: responseIndicador) => {
      console.log("CAMBIO DOLARES", resp);
      if(resp.response == 'OK' && resp.ListaIndicadores.length > 0){
        this.cambioDolares = resp.ListaIndicadores[0].valor;
      }
    }, error => {
      console.error('CAMBIO DOLARES', error);
      const date = new Date();
      date.setDate(date.getDate() - 2);

      this.indicadoresService.getCambioDolarByDate(date, new Date()).subscribe((resp: responseIndicador) => {
        if(resp.response == 'OK' && resp.ListaIndicadores.length > 0){
          this.cambioDolares = resp.ListaIndicadores[0].valor;
        }
      });
    });
  }

  fileSelected(event){
    console.log("FILE SELECTED", this.uploader);
  }

  fileOverBase(e:any):void {
    console.log('FILE OVER', e);
    this.hasBaseDropZoneOver = e;
  }

  upload(){
    console.log('FILES TO UPLOAD', this.uploader);
    // this.uploader.queue.forEach((file) => {
    //   this.matchArray.push({ nombre: file.file.name, nombreNuevo: file['nombreNuevo'], tipoArchivo: file['tipoArchivo'], expiracion: file['expiracion']});
    // });
    
    // console.log(this.matchArray);

    this.uploader.uploadAll();
  }

  reiniciar(){
    this.uploader.clearQueue();
    this.arrResp = new Array<cargaPagosResult>();
  }

}
