declare var $: any;

export function showNotification(message: string, type: string, icon?: string){
    if(!icon){
        switch(type){
            case 'succes':
                icon = 'glyphicon glyphicon-ok-sign';
                break;
            case 'danger':
                icon = 'glyphicon glyphicon-remove-sign';
                break;
            case 'warning':
                icon = 'glyphicon glyphicon-exclamation-sign';
                break;
            default:
                icon = 'glyphicon glyphicon-info-sign';
                break;
        }
    }

    $.notify({
            icon: icon,
            message: message
        },{
            type: type,
            timer: 4000,
            placement: {
                from: 'top',
                align: 'right'
            },
            z_index: 99999
    });
}