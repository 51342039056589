import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PolizasService } from 'app/shared/services/polizas.service';
import { poliza } from 'app/shared/models/poliza';
import { showNotification } from 'app/shared/factories/notifications';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, catchError } from 'rxjs/operators';
import { PolizaDetailsComponent } from '../poliza-details/poliza-details.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { RamosService } from 'app/shared/services/ramos.service';
import { ramo } from 'app/shared/models/ramo';
import { estatusTramite } from 'app/shared/models/tramite';
import { TramitesService } from 'app/shared/services/tramites.service';
import { EstatusPolizaService } from 'app/shared/services/estatus-poliza.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'component-polizas-list',
  templateUrl: './polizas-list.component.html',
  styleUrls: ['./polizas-list.component.css']
})
export class PolizasListComponent implements OnInit {

  @Input() fromModal: boolean;
  @Input() modalAdd: boolean;
  @Input('agente-id') agenteId: number;
  @Input('cliente-id') clienteId: number;
  polizas: poliza[];
  search: string;
  pagination: any;
  isWorking: boolean;
  allowModify: boolean;
  ffin: Date;
  finicio: Date;
  ramos: ramo[];
  estatuses: estatusTramite[];
  etramite: number;
  eramo: number;

  displayedColumns: string[] = ['Polizas.Id', 'Agentes.Nombre', 'Clientes.Nombre', 'Polizas.FechaEmision', 'Polizas.FechaRenovacion', 'Polizas.NumeroPoliza', 'Ramos.Nombre', 'Productos.Nombre', 'Polizas.MontoPoliza', 'ConductosCobro.Nombre', 'FormasPago.Nombre', 'EstatusPoliza.Nombre', 'Delete'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public polizasService: PolizasService, 
    public authService: AuthService,
    public modal: NgbModal, 
    public router: Router, 
    public activatedRoute: ActivatedRoute, 
    public estatusPolizaService: EstatusPolizaService,
    public ramoService: RamosService,
    public activeModal: NgbActiveModal) { 
    this.polizas = [];
    this.search = '';
    this.isWorking = false;
    /*this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'id_desc',
      pageSize: 50
    };*/
    this.ffin = new Date();
    this.finicio = new Date(2000,1,1);
    this.ramos = new Array<ramo>();
    this.estatuses = new Array<estatusTramite>();
    this.etramite = 0;
    this.eramo = 0;
    
    this.allowModify = this.authService.idagente == 0;
  }

  ngOnInit(): void {
    this.getRamos();
    this.getEstatuses();
    //this.paginate(this.pagination.page);
    
  }

  ngAfterViewInit(){
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.load();
  }

  load(){
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.polizasService.get(this.search, 
            this.finicio, 
            this.ffin, 
            this.eramo, 
            this.etramite, 
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex, this.agenteId, this.clienteId).pipe(catchError((err) => of(null)));
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          console.log(data);
          this.isLoadingResults = false;
          this.isRateLimitReached = data.data.length === 0;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.totalItems;
          return data.data;
        }),
      )
      .subscribe(data => (this.polizas = data));
  }

  getRamos(){
    this.ramoService.get().subscribe(resp => {
      console.log('GET RAMOS', resp);
      if(resp.status){
        this.ramos = resp.data;
      }
    });
  }

  getEstatuses(){
    this.estatusPolizaService.get().subscribe(resp => {
      console.log('GET ESTATUSES', resp);
      if(resp.status){
        this.estatuses = resp.data;
      }
    }, err => {
      console.error('GETTING ESTATUSES', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'warning');
    });
  }

  paginate(page){
    this.toggleWorking();

    this.polizasService.get(this.search, 
      this.finicio, 
      this.ffin, 
      this.eramo, 
      this.etramite, 
      this.pagination.order, page, this.agenteId, this.clienteId).subscribe(resp => {
      console.log('GET POLIZAS', resp);
      if(resp.status){
        this.polizas = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
      this.toggleWorking();
    }, err => {
      console.error('GETTING POLIZAS', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
      this.toggleWorking();
    });
  }

  checkDelete(poliza){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(poliza);
      }
    }, reject => {});
  }

  delete(poliza){
    this.polizasService.delete(poliza.id).subscribe(resp => {
      console.log('POLIZA USUARIO', resp);
      if(resp.status){
        this.load();
        showNotification('Poliza eliminada', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING POLIZA', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.load())
    )

  detail(id){
  if(this.modalAdd){
    const modal = this.modal.open(PolizaDetailsComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'p-5'});
    modal.componentInstance.idPoliza = id;
    modal.componentInstance.idAgente = this.agenteId;
    modal.componentInstance.idCliente = this.clienteId;
    modal.result.then(result => {
      if(result){
        this.load();
      }
    }, reject => {
      this.load();
    });
    }
    else{
      this.router.navigate(['./edit/', id], {relativeTo: this.activatedRoute});
    }
  }
}
