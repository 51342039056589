import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { usuario } from '../models/usuario';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    token: string;
    idprofile: number;
    id: number;
    idagente: number;

    private base_url: string;

    constructor(public http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.token = '';
        this.idprofile = 0;
        this.id = 0;
        this.idagente = 0;

        console.log(baseUrl);

        this.base_url = baseUrl;
    }

    signupUser(email: string, password: string) {
        //your code for signing up the new user
    }

    signinUser(usuario: usuario): Observable<any> {
        //your code for checking credentials and getting tokens for for signing in user

        return this.http.post(this.base_url + "auth/login", usuario, httpOptions);
    }

    logout() {
        this.token = null;
    }

    getToken() {
        return this.token;
    }

    isAuthenticated(): Observable<any> {
        // here you can check if user is authenticated or not through his token
        console.log('AUTH SERVICE TOKEN', this.getToken());
        return this.http.post(this.base_url.concat('auth/isactive'), {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.getToken()
            }
        });
    }

}
