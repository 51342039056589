import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { cliente } from '../models/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(search: string, order: number, page: number, agenteId?: number): Observable<any>{
    agenteId = agenteId ? agenteId : 0;
    
    return this.http.get(this.base_url.concat('clientes?', 'search=', search, '&order=', order.toString(), '&page=', page.toString(), '&agenteId=', agenteId.toString()), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getById(id): Observable<any>{
    return this.http.get(this.base_url.concat('clientes/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(cliente): Observable<any>{
    cliente.agenteId = cliente.agenteId != '0' ? cliente.agenteId*1 : null;
    cliente.clienteDesde = cliente.clienteDesde == 'N/A' ? null : cliente.clienteDesde;

    return this.http.post(this.base_url.concat('clientes'), cliente, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('clientes/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getCumples(mes: number): Observable<any>{
    
    return this.http.get(this.base_url.concat('clientes/','cumpleanos/', mes.toString()), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }
}
