import { cliente } from "./cliente";
import { agente } from "./agente";
import { ramo } from "./ramo";
import { producto } from "./producto";
import { conductoCobro } from "./conductoCobro";
import { formaPago } from "./formaPago";
import { moneda } from "./moneda";
import { estatusPoliza } from "./estatusPoliza";
import { proveedor } from "./proveedor";
import { usuario } from "./usuario";

export class poliza{
    id?: number;
    agenteId?: number;
    clienteId?: number;
    ramoId?: number;
    productoId?: number;
    conductoCobroId?: number;
    formaPagoId?: number;
    monedaId?: number;
    temporalidad?: number;
    estatusPolizaId?: number;
    proveedorId?: number;
    tieneAgenteSecundario?: boolean;
    agenteSecundarioId?: number;
    participacion?: number;

    fechaEmision?: Date;
    fechaRenovacion?: Date;
    numeroPoliza?: string;
    montoPoliza?: number;
    preexistente?: boolean;
    comentarios?: string;
    expiracion?: Date;
    creadoPorId?: number;
    coaseguro?: any;
    deducible?: number;
    sumaAsegurada?: number;
    sinLimite?: boolean;
    referenciaDePago?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    agente?: agente;
    cliente?: cliente;
    ramo?: ramo;
    producto?: producto;
    conductoCobro?: conductoCobro;
    formaPago?: formaPago;
    moneda?: moneda;
    estatusPoliza?: estatusPoliza;
    proveedor?: proveedor;
    creadoPor?: usuario;

    constructor(){
        this.id = 0;
        this.agenteId = null;
        this.clienteId = null;
        this.ramoId = null;
        this.productoId = null;
        this.conductoCobroId = null;
        this.formaPagoId = null;
        this.monedaId = 1;
        this.estatusPolizaId = 3;
        this.proveedorId = 1;
        this.agenteSecundarioId = null;
        this.tieneAgenteSecundario = false;
        this.participacion = null;
        this.sumaAsegurada = null;
        this.referenciaDePago = null;
        this.temporalidad = 1;

        this.fechaEmision = new Date();
        this.fechaEmision.setHours(0,0,0,0);
        this.fechaRenovacion = new Date();
        this.fechaRenovacion.setHours(0,0,0,0);
        this.numeroPoliza = null;
        this.montoPoliza = null;
        this.preexistente = false;
        this.comentarios = null;
        this.expiracion = new Date();
        this.expiracion.setHours(0,0,0,0);
        this.creadoPorId = 0;
        this.coaseguro = null;
        this.deducible = null;

        this.sinLimite = false;
        // this.agente = new agente();
        // this.cliente = new cliente();
        // this.ramo = new ramo();
        // this.producto = new ramo();
        // this.conductoCobro = new conductoCobro();
        // this.formaPago = new formaPago();
        // this.moneda = new moneda();
        // this.estatusPoliza = new estatusPoliza();
        // this.proveedor = new proveedor();
        // this.creadoPor = new usuario();
    }

}