import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AgentesService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  all(): Observable<any>{
    return this.http.get(this.base_url.concat('agentes'), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  get(search: string, order: any, page: number): Observable<any>{
    return this.http.get(this.base_url.concat('agentes?', 'search=', search, '&order=', order.toString(), '&page=', page.toString()), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getById(id): Observable<any>{
    return this.http.get(this.base_url.concat('agentes/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getByUserId(id): Observable<any>{
    return this.http.get(this.base_url.concat('agentes/byuser/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(agente): Observable<any>{
    agente.usuarioId = (agente.usuarioId != '0' && agente.usuarioId != 0 && agente.usuarioId != undefined) ? agente.usuarioId*1 : null;
    return this.http.post(this.base_url.concat('agentes'), agente, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('agentes/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }
}
