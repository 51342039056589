import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TramitesService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  getTramitesAgente(search: string, estatus: number, tipo: number, order: number, page: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/agente?', 
    'search=', search, 
    '&estatus=', estatus.toString(),
    '&tipo=', tipo.toString(),
    '&order=', order.toString(), 
    '&page=', page.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id, 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  exportTramitesAgente(search: string, estatus: number, tipo: number, order: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<Blob>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/agente/export?',
    'search=', search, 
    '&estatus=', estatus.toString(),
    '&tipo=', tipo.toString(),
    '&order=', order.toString(),
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id, 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      responseType: 'blob',
      headers: {
        'Authorization': this.authService.getToken()
    }
    });
  }

  getTramitesCliente(search: string, estatus: number, tipo: number, order: number, page: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/cliente?', 
    'search=', search, 
    '&estatus=', estatus.toString(),
    '&tipo=', tipo.toString(),
    '&order=', order.toString(), 
    '&page=', page.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id, 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  exportTramitesCliente(search: string, estatus: number, tipo: number, order: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/cliente/export?', 
    'search=', search, 
    '&estatus=', estatus.toString(),
    '&tipo=', tipo.toString(),
    '&order=', order.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id, 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      responseType: 'blob',
      headers: {
          'Authorization': this.authService.getToken()
      }
    });
  }

  getTramitesPoliza(search: string, estatus: number, ramo: number, tipo: number, order: number, page: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean, agenteId?: number): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/poliza?', 
    'search=', search, 
    '&estatus=', estatus.toString(), 
    '&tipo=', tipo.toString(),
    '&ramo=', ramo.toString(),
    '&order=', order.toString(), 
    '&page=', page.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id, 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false',
    '&agenteId=', agenteId ? agenteId.toString() : ''
    ), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  exportTramitesPoliza(search: string, estatus: number, ramo: number, tipo: number, order: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/poliza/export?', 
    'search=', search, 
    '&estatus=', estatus.toString(), 
    '&tipo=', tipo.toString(),
    '&ramo=', ramo.toString(),
    '&order=', order.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id, 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      responseType: 'blob',
      headers: {
          'Authorization': this.authService.getToken()
      }
    });
  }

  getTramitesSiniestro(search: string, estatus: number, tipo: number, order: number, page: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/siniestro?', 
    'search=', search, 
    '&estatus=', estatus.toString(), 
    '&tipo=', tipo.toString(),
    '&order=', order.toString(), 
    '&page=', page.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id ? id : '0', 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  exportTramitesSiniestro(search: string, estatus: number, tipo: number, order: number, finicio: Date, ffin: Date, id?, own?: boolean, porfecha?: boolean): Observable<any>{
    finicio.setHours(0,0,0,0);
    ffin.setHours(23,59,59,999);
    return this.http.get(this.base_url.concat('tramites/siniestro/export?', 
    'search=', search, 
    '&estatus=', estatus.toString(), 
    '&tipo=', tipo.toString(),
    '&order=', order.toString(), 
    '&finicio=', finicio.toDateString(), 
    '&ffin=', ffin.toDateString(), 
    '&id=', id ? id : '0', 
    '&own=', own ? 'true' : 'false',
    '&porfecha=', porfecha ? 'true' : 'false'
    ), {
      responseType: 'blob',
      headers: {
          'Authorization': this.authService.getToken()
      }
    });
  }

  getById(id, type): Observable<any>{
    return this.http.get(this.base_url.concat('tramites/', id, '?type=', type), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(tramite, entityId, type: string): Observable<any>{
    entityId = entityId*1;

    return this.http.post(this.base_url.concat('tramites'), {tramite: tramite.tramite, type, entityId, monto: tramite.montoReembolso ? tramite.montoReembolso : -1}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  saveTramiteSiniestro(tramite): Observable<any>{
    tramite.montoReembolso = tramite.montoReembolso*1;
    return this.http.post(this.base_url.concat('tramites/siniestro'), tramite, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('tramites/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getTipos(categoriaId: number): Observable<any>{
    return this.http.get(this.base_url.concat('tiposTramite?CategoriaId=', categoriaId.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getFilas(categoriaId: number): Observable<any>{
    return this.http.get(this.base_url.concat('filas?CategoriaId=', categoriaId.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getEstatus(): Observable<any>{
    return this.http.get(this.base_url.concat('estatusTramite'), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

}
