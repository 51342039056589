import { Component, OnInit, Input } from '@angular/core';
import { siniestro } from 'app/shared/models/siniestro';
import { SiniestrosService } from 'app/shared/services/siniestros.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { showNotification } from 'app/shared/factories/notifications';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SiniestroDetailsComponent } from '../siniestro-details/siniestro-details.component';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'component-siniestros-list',
  templateUrl: './siniestros-list.component.html',
  styleUrls: ['./siniestros-list.component.css']
})
export class SiniestrosListComponent implements OnInit {
  @Input() fromModal: boolean;
  @Input() modalAdd: boolean;
  @Input('poliza-id') polizaId: number;

  siniestros: Array<siniestro>;
  search: string;
  pagination: any;
  isWorking: boolean;
  allowModify: boolean;

  constructor(public siniestrosService: SiniestrosService, 
    public authService: AuthService,
    public modal: NgbModal, 
    public router: Router, 
    public activatedRoute: ActivatedRoute, 
    public activeModal: NgbActiveModal) { 
    this.siniestros = new Array<siniestro>();
    this.search = '';
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'id_desc',
      pageSize: 50
    };

    this.allowModify = this.authService.idagente == 0;
  }

  ngOnInit(): void {
    //if(this.polizaId !=)

    this.paginate(this.pagination.page);
  }

  paginate(page){
    this.toggleWorking();

    this.siniestrosService.get(this.search, this.pagination.order, page, this.polizaId).subscribe(resp => {
      console.log('GET SINIESTROS', resp);
      if(resp.status){
        this.siniestros = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
      this.toggleWorking();
    }, err => {
      console.error('GETTING SINIESTROS', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
      this.toggleWorking();
    });
  }

  checkDelete(siniestro){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(siniestro);
      }
    }, reject => {});
  }

  delete(siniestro){
    this.siniestrosService.delete(siniestro.id).subscribe(resp => {
      console.log('SINIESTRO DELETE', resp);
      if(resp.status){
        this.paginate(1);
        showNotification('Siniestro eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING SINIESTRO', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

  detail(id){
  if(this.modalAdd){
    const modal = this.modal.open(SiniestroDetailsComponent, {size: 'lg', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.idSiniestro = id;
    modal.componentInstance.idPoliza = this.polizaId;
    modal.result.then(result => {
      if(result){
        this.paginate(1);
      }
    }, reject => {
      this.paginate(1);
    });
    }
    else{
      this.router.navigate(['./edit/', id], {relativeTo: this.activatedRoute});
    }
  }

}
