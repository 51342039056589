import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from './auth.service';

@Injectable()
export class InitializerProviderService {

    constructor(public localStorage: StorageMap, public authService: AuthService) {

    }

    load() {
        return new Promise((resolve, reject) => {
            this.localStorage.get('user')
                .subscribe((user: any) => {
                  if(user){
                      this.authService.id = user.id;
                      this.authService.idprofile = user.perfilId;
                      this.authService.idagente = user.agenteId;
                      this.authService.token = user.token;
                      console.log('INIT TOKEN', this.authService.token);
                  }
                  resolve(true);
                });
        })
    }
}
