import { Component, OnInit, Input } from '@angular/core';
import { historialPagosPoliza } from 'app/shared/models/historialPagosPoliza';
import { HistorialPagosPolizaService } from 'app/shared/services/historial-pagos-poliza.service';
import { AuthService } from 'app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { showNotification } from 'app/shared/factories/notifications';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'component-historial-pagos-poliza-list',
  templateUrl: './historial-pagos-poliza-list.component.html',
  styleUrls: ['./historial-pagos-poliza-list.component.css']
})
export class HistorialPagosPolizaListComponent implements OnInit {

  @Input('poliza-id') polizaId: number;

  historial: Array<historialPagosPoliza>;
  search: string;
  //ffin: Date;
  //finicio: Date;
  //own: boolean = true;
  pagination: any;
  isWorking: boolean;

  constructor(
    public historialService: HistorialPagosPolizaService,
    public authService: AuthService, 
    public activatedRoute: ActivatedRoute, 
    public modal: NgbModal,
    public router: Router) { 
      this.historial = new Array<historialPagosPoliza>();
      this.search = '';
    // this.ffin = new Date();
    // this.finicio = new Date();
    // this.finicio.setDate(this.ffin.getDate() - 30);
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      // order: 'id_desc',
      pageSize: 50
    };
  }

  ngOnInit(): void {
    this.paginate(this.pagination.page);
  }

  paginate(page){
    this.toggleWorking();

    this.historialService.get(this.search, page, this.polizaId).subscribe(resp => {
      console.log('GET HISTORIAL', resp);
      if(resp.status){
        this.historial = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
      this.toggleWorking();
    }, err => {
      console.error('GETTING HISTORIAL', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
      this.toggleWorking();
    });
  }

  checkDelete(historial){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(historial);
      }
    }, reject => {});
  }

  delete(historial){
    this.historialService.delete(historial.id).subscribe(resp => {
      console.log('DELETE HISTORIAL', resp);
      if(resp.status){
        this.paginate(1);
        showNotification('Historial de Pago eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING HISTORIAL', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

  // detail(id){
  // if(this.modalAdd){
  //   const modal = this.modal.open(HistorialPrimaNetaDetailsComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
  //   modal.componentInstance.id = id;
  //   modal.componentInstance.entityId = this.polizaId;
  //   modal.result.then(result => {
  //     this.paginate(1);
  //   }, reject => {
  //     this.paginate(1);
  //   });
  //   }
  //   else{
  //     this.router.navigate(['./edit/', id], {relativeTo: this.activatedRoute});
  //   }
  // }

}
