import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { StorageMap } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private storage: StorageMap) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.isAuthenticated().pipe(catchError (val => of(`ERROR: ${val}`).pipe(map(err => {
            console.error('GUARD ERR', err);
            return { status: false };
        }))), map(res => {
            console.log('GUARD RES', res);
          if (!res.status || res.data.perfilId != 1) {
              this.storage.clear();
              this.router.navigate(['public', 'login']);
          }

            return res.status;
        }));
    }
}
