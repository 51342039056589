import { Component, OnInit, Inject, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { poliza } from 'app/shared/models/poliza';
import { agente } from 'app/shared/models/agente';
import { ActivatedRoute, Router } from '@angular/router';
import { RamosService } from 'app/shared/services/ramos.service';
import { ProductosService } from 'app/shared/services/productos.service';
import { PolizasService } from 'app/shared/services/polizas.service';
import { ConductosCobroService } from 'app/shared/services/conductos-cobro.service';
import { FormasPagoService } from 'app/shared/services/formas-pago.service';
import { MonedasService } from 'app/shared/services/monedas.service';
import { EstatusPolizaService } from 'app/shared/services/estatus-poliza.service';
import { ProveedorService } from 'app/shared/services/proveedor.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/services/auth.service';
import { ramo } from 'app/shared/models/ramo';
import { producto } from 'app/shared/models/producto';
import { conductoCobro } from 'app/shared/models/conductoCobro';
import { formaPago } from 'app/shared/models/formaPago';
import { moneda } from 'app/shared/models/moneda';
import { estatusPoliza } from 'app/shared/models/estatusPoliza';
import { proveedor } from 'app/shared/models/proveedor';
import { showNotification } from 'app/shared/factories/notifications';
import { AgentesListComponent } from 'app/components/agentes/agentes-list/agentes-list.component';
import { ClientesListComponent } from 'app/components/clientes/clientes-list/clientes-list.component';
import { AgentesService } from 'app/shared/services/agentes.service';
import { ClientesService } from 'app/shared/services/clientes.service';
import { cliente } from 'app/shared/models/cliente';
import { usuario } from 'app/shared/models/usuario';
import { ClienteDetailsComponent } from 'app/components/clientes/cliente-details/cliente-details.component';
import { isNull } from 'util';
import { HistorialPrimaNetaDetailsComponent } from 'app/components/historial-prima-neta/historial-prima-neta-details/historial-prima-neta-details.component';
import { HistorialPrimaNetaComponent } from 'app/components/historial-prima-neta/historial-prima-neta/historial-prima-neta.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'component-poliza-details',
  templateUrl: './poliza-details.component.html',
  styleUrls: ['./poliza-details.component.css']
})
export class PolizaDetailsComponent implements OnInit {

  @Input() fromModal: boolean;
  @Input() idPoliza: string;
  @Input() idAgente: string;
  @Input() idCliente: string;
  poliza: poliza;
  isWorking: boolean;
  allowModify: boolean;

  ramos: Array<ramo>;
  productos: Array<producto>;
  conductosCobro: Array<conductoCobro>;
  formasPago: Array<formaPago>;
  monedas: Array<moneda>;
  estatuses: Array<estatusPoliza>;
  proveedores: Array<proveedor>;
  agentes: Array<agente>;
  arrTemp: number[] = [];
  hasPrimaNeta: boolean = false;

  @ViewChild('historialpn') historialPN: HistorialPrimaNetaComponent;

  @Output() dateChange:EventEmitter<MatDatepickerInputEvent<any>>;

  constructor(public route: ActivatedRoute, 
    public polizasService: PolizasService,
    public ramosService: RamosService, 
    public productosService: ProductosService,
    public conductosService: ConductosCobroService,
    public formaPagoService: FormasPagoService,
    public monedaService: MonedasService,
    public estatusService: EstatusPolizaService,
    public proveedorService: ProveedorService,
    public agentesService: AgentesService,
    public clientesService: ClientesService,
    public modal: NgbModal,
    public router: Router, @Inject('BASE_URL') base_url: string,
    public activeModal: NgbActiveModal,
      public authService: AuthService) { 
        //this.idPoliza = '0';
        this.poliza = new poliza();
        this.poliza.agente = new agente();
        this.poliza.cliente = new cliente();
        this.poliza.creadoPor = new usuario();

        this.ramos = new Array<ramo>();
        this.conductosCobro = new Array<conductoCobro>();
        this.productos = new Array<producto>();
        this.formasPago = new Array<formaPago>();
        this.monedas = new Array<moneda>();
        this.estatuses = new Array<estatusPoliza>();
        this.proveedores = new Array<proveedor>();
        this.agentes = new Array<agente>();

        for(let n = 1; n < 100; n++){
          this.arrTemp.push(n);
        }

        this.isWorking = false;

        this.allowModify = this.authService.idagente == 0;
  }

  ngOnInit(): void {
    console.log('ID POLIZA EN POLIZA DETAILS', this.idPoliza);
    if(this.idPoliza == undefined){
      this.idPoliza = this.route.snapshot.paramMap.get('id');
    }

    if(this.idAgente != undefined){
      this.getAgente(this.idAgente);
    }

    if(this.idPoliza != '0' && this.idPoliza != undefined){
      this.getPoliza(this.idPoliza);
    }

    if(this.idCliente != '0' && this.idCliente != undefined){
      this.getCliente(this.idCliente);
    }

    this.getRamos();
    this.getConductos();
    this.getFormas();
    this.getMonedas();
    this.getEstatuses();
    this.getProveedores();
    this.getAgentes();
  }

  getCliente(id){
    this.clientesService.getById(id).subscribe(resp => {
      console.log('GET CLIENTE', resp);
      if(resp.status){
        this.poliza.cliente = resp.data;
        this.poliza.clienteId = this.poliza.cliente.id;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING CLIENTE', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    });
  }

  getAgente(id){
    this.agentesService.getById(id).subscribe(resp => {
      console.log('GET AGENTE', resp);
      if(resp.status){
        this.poliza.agente = resp.data;
        this.poliza.agenteId = this.poliza.agente.id;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING AGENTE', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    });
  }

  getAgentes(){
    if(this.allowModify){
      this.agentesService.all().subscribe(resp => {
        if(resp.status){
          this.agentes = resp.data;
        }else{
          showNotification(resp.message, 'warning');
        }
      }, err => {
        console.error('GETTING AGENTES', err);
        showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
      });
    }
  }

  getProveedores() {
    this.proveedorService.get().subscribe(resp => {
      console.log('GET PROVEEDIRES', resp);
      if(resp.status){
        this.proveedores = resp.data;
      }
    }, err => {
      console.error('GETTING PROVEEDORES', err);
      showNotification('Ocurrió un error inesperado al obtener proveedores, verifica tu conexión a internet', 'warning');
    });
  }
  getEstatuses() {
    this.estatusService.get().subscribe(resp => {
      console.log('GET ESTATUSES', resp);
      if(resp.status){
        this.estatuses = resp.data;
      }
    }, err => {
      console.error('GETTING ESTATUSES', err);
      showNotification('Ocurrió un error inesperado al obtener los estatus, verifica tu conexión a internet', 'warning');
    });
  }
  getMonedas() {
    this.monedaService.get().subscribe(resp => {
      console.log('GET MONEDAS', resp);
      if(resp.status){
        this.monedas = resp.data;
      }
    }, err => {
      console.error('GETTING MONEDAS', err);
      showNotification('Ocurrió un error inesperado al obtener las monedas, verifica tu conexión a internet', 'warning');
    });
  }
  getFormas() {
    this.formaPagoService.get().subscribe(resp => {
      console.log('GET FORMAS PAGO', resp);
      if(resp.status){
        this.formasPago = resp.data;
      }
    }, err => {
      console.error('GETTING FORMAS PAGO', err);
      showNotification('Ocurrió un error inesperado al obtener las formas de pago, verifica tu conexión a internet', 'warning');
    });
  }
  getProductos(idRamo?) {
    this.productosService.get(idRamo).subscribe(resp => {
      console.log('GET PRODUCTOS', resp);
      if(resp.status){
        this.productos = resp.data;
      }
    }, err => {
      console.error('GETTING PRODUCTOS', err);
      showNotification('Ocurrió un error inesperado al obtener los productos, verifica tu conexión a internet', 'warning');
    });
  }
  getConductos() {
    this.conductosService.get().subscribe(resp => {
      console.log('GET CONDUCTOS COBRO', resp);
      if(resp.status){
        this.conductosCobro = resp.data;
      }
    }, err => {
      console.error('GETTING CONDUCTOS COBRO', err);
      showNotification('Ocurrió un error inesperado al obtener los conductos de cobro, verifica tu conexión a internet', 'warning');
    });
  }
  getRamos() {
    this.ramosService.get().subscribe(resp => {
      console.log('GET RAMOS', resp);
      if(resp.status){
        this.ramos = resp.data;
      }
    }, err => {
      console.error('GETTING RAMOS', err);
      showNotification('Ocurrió un error inesperado al obtener los ramos, verifica tu conexión a internet', 'warning');
    });
  }

  getPoliza(id, cllbck?){
    this.toggleWorking();

    this.polizasService.getById(id).subscribe(resp => {
      console.log('GET POLIZA', resp);
      if(resp.status){
        this.poliza = resp.data;
        if(this.poliza.agente == null){
          this.poliza.agente = new agente();
          this.poliza.agenteId = 0;
        }
        if(this.poliza.cliente == null){
          this.poliza.cliente = new cliente();
          this.poliza.clienteId = 0;
        }
        if(this.poliza.agenteSecundarioId == null){
          this.poliza.tieneAgenteSecundario = false;
        }
        if(cllbck != undefined){
          cllbck(this.poliza);
        }

        this.poliza.coaseguro = this.poliza.coaseguro == 0 ? '0' : this.poliza.coaseguro;

        this.hasPrimaNeta = !isNull(this.poliza.montoPoliza) && this.poliza.montoPoliza != 0;

        this.getProductos(this.poliza.ramoId);
      }else{
        showNotification(resp.message, 'danger');
        this.router.navigate(['admin','polizas']);
      }
    }, err => {
      console.error('GETTING POLIZA', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo', 'danger');
      this.router.navigate(['admin','polizas']);
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  save(poliza){
    console.log('POLIZA TO SAVE', poliza);
    this.toggleWorking();

    this.polizasService.save(poliza).subscribe(resp => {
      console.log('SAVE POLIZA', resp);
      if(resp.status && this.idPoliza == '0'){
        this.idPoliza = resp.data;
      }
      this.getPoliza(this.idPoliza, (poliza) => {
        if(this.fromModal){
          this.activeModal.close(poliza);
        }else{
          this.activeModal.dismiss();
        }
      });
      this.historialPN.ngOnInit();
      showNotification(resp.message, resp.status ? 'success' : 'danger');
    }, err => {
      console.error('ERROR SAVING POLIZA', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  secundarioChanged(event){
    if(!this.poliza.tieneAgenteSecundario){
      this.poliza.agenteSecundarioId = null;
      this.poliza.participacion = null;
    }
  }

  openAgentes(){
    const modal = this.modal.open(AgentesListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.poliza.agente = result;
        this.poliza.agenteId = result.id;
      }
    }, reject => {});
  }

  openClientes(){
    const modal = this.modal.open(ClientesListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.componentInstance.agenteId = this.poliza.agenteId;
    modal.result.then(result => {
      if(result){
        this.poliza.agente = result.agente;
        this.poliza.agenteId = result.agente.id;
        this.poliza.cliente = result;
        this.poliza.clienteId = result.id;
      }
    }, reject => {});
  }

  openClientesDetail(){
    const modal = this.modal.open(ClienteDetailsComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.componentInstance.clienteId = this.poliza.clienteId;
    modal.componentInstance.agenteId = this.poliza.agenteId;

    modal.result.then(result => {
      if(result){
        this.poliza.agente = result.agente;
        this.poliza.agenteId = result.agente.id;
        this.poliza.cliente = result;
        this.poliza.clienteId = result.id;
      }
    }, reject => {});
  }

  openHistDetail(){
    const modal = this.modal.open(HistorialPrimaNetaDetailsComponent, {size: 'md', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.polizaId = this.poliza.id;
    modal.componentInstance.temporalidad = this.poliza.temporalidad;

    modal.result.then(result => {
      console.log("RET HISTORIAL PRIMA NETA", result);
      this.poliza.montoPoliza = result.primaNeta;
      this.poliza.referenciaDePago = result.referenciaPago;
      this.save(this.poliza);
      this.historialPN.ngOnInit();
    }, reject => {});
  }

  ramoChanged(ramoId){
    this.getProductos(ramoId);
  }

  calcExpiracion(temporalidad: number, emision: Date){
    console.log("TEMPORALIDAD", temporalidad);
    console.log("EMISION", emision);
    console.log("Fecha Renovacion", this.poliza.fechaRenovacion);

    if(temporalidad == null){
      this.poliza.temporalidad = 1;
      temporalidad = 1;
    }

    var date = new Date(emision);

    if(temporalidad > 0 && emision != null){
      this.poliza.expiracion = new Date(date.setFullYear(date.getFullYear() + temporalidad));
    }
  }

  emisionChange(temporalidad: number, emision: Date){
    if(this.poliza.fechaRenovacion == null){
      this.calcExpiracion(temporalidad, emision);
    }
  }

  renovacionChange(temporalidad: number, emision: Date){
    this.calcExpiracion(temporalidad, emision);
  }

}
