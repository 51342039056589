import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormatosService } from 'app/shared/services/formatos.service';
import { AuthService } from 'app/shared/services/auth.service';
import { formato } from 'app/shared/models/formato';
import { ActivatedRoute } from '@angular/router';
import { showNotification } from 'app/shared/factories/notifications';
import { FileUploader } from 'ng2-file-upload';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-formatos-detail',
  templateUrl: './formatos-detail.component.html',
  styleUrls: ['./formatos-detail.component.css']
})
export class FormatosDetailComponent implements OnInit {

  @Input() idFormato: number;
  formato: formato;
  isWorking: boolean = false;
  baseUrl: string = "";
  fileUrl: string = "";
  hasFile: boolean = false;
  allowModify: boolean;

  uploader: FileUploader;

  constructor(public dialogRef: NgbActiveModal, 
    private formatosService: FormatosService, 
    private authService: AuthService,
    @Inject('BASE_URL') base_url: string,
    @Inject('FILE_URL') file_url: string) { 
      
      this.baseUrl = base_url;
      this.fileUrl = file_url;
      this.formato = new formato();
      this.uploader = new FileUploader({
        url: this.baseUrl.concat('formatos/file?folder=formatos'),
        disableMultipart: false,
        authToken: this.authService.getToken(),
        //allowedMimeType: ['image/jpg', 'image/png', 'image/jpeg'],
        itemAlias: 'file',
        isHTML5: true,
      });

      this.allowModify = this.authService.idagente == 0;

      this.uploader.onErrorItem = (item, response, status, headers) => {
        console.error("FILE UPLOAD ERROR", response);
        showNotification('No se pudo subir el archivo ' + item.file.name + ' intentalo nuevamente o con un archivo más ligero', 'danger');
      };
  
      this.uploader.onSuccessItem = (item, response, status, headers) => {
        console.log('FILE UPLOADED response', response);
        console.log('FILE UPLOADED item', item);
        console.log('FILE UPLOADED status', status);

        let resp = JSON.parse(response);

        if(resp.status){
          this.formato.url = resp.data;
          this.saveFormato();
        }else{
          showNotification(resp.message, 'danger');
        }

        //showNotification(item.file.name.concat(' Guardado con Éxito'), 'success');
      };
  
      this.uploader.onBuildItemForm = (item, form) => {
        // form.append('match', JSON.stringify(this.matchArray));
        // form.append('entity', JSON.stringify(this.data));
      };
  
      this.uploader.onBeforeUploadItem = (item) => {
        item.withCredentials = false;
      }
  }

  ngOnInit(): void {
    this.idFormato = this.idFormato == undefined ? 0 : this.idFormato;

    if(this.idFormato != 0){
      this.getFormato(this.idFormato);
    }
  }

  getFormato(id){
    this.isWorking = true;
    this.formatosService.getById(id).subscribe(resp => {
      console.log('GET FORMATO', resp);
      if(resp.status){
        this.formato = resp.data;
        if(this.formato.url != ''){
          this.hasFile = true;
        }
      }else{
        showNotification(resp.message, 'danger');
      }

      this.isWorking = false;
    }, err => {
      console.error('GET FORMATO', err);
      showNotification('Ocurrió un error inesperado, verifica tu conexión a internet', 'danger');
      this.isWorking = false;
    });
  }

  save(){
    if(this.uploader.queue.length > 0){
      this.uploader.uploadAll();
    }else{
      this.saveFormato();
    }

  }

  saveFormato() {
    this.isWorking = true;
    this.formatosService.save(this.formato).subscribe(resp => {
      console.log('SAVING FORMATO', resp);
      if(resp.status){
        this.idFormato = resp.data;
        this.getFormato(this.idFormato);
        this.isWorking = false;
        this.dialogRef.dismiss();
      } else {
        this.isWorking = false;
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('SAVING FORMATO ERROR', err);
      showNotification('Ocurrió un error inesperado, verifica tu conexión a internet', 'danger');
      this.isWorking = false;
    });
  }

  openFile(file){
    window.open(this.fileUrl + file, '_blank');
  }

}
