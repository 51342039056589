import { Component, OnInit, Inject, Input } from '@angular/core';
import { cliente } from 'app/shared/models/cliente';
import { FileUploader } from 'ng2-file-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from 'app/shared/services/clientes.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/services/auth.service';
import { EstadosService } from 'app/shared/services/estados.service';
import { showNotification } from 'app/shared/factories/notifications';
import { AgentesListComponent } from 'app/components/agentes/agentes-list/agentes-list.component';
import { estado } from 'app/shared/models/estado';
import { agente } from 'app/shared/models/agente';
import { usuario } from 'app/shared/models/usuario';
import { AgentesService } from 'app/shared/services/agentes.service';
import { FormControl } from '@angular/forms';
import { GeonamesService } from 'app/shared/services/geonames.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { colonia } from 'app/shared/models/colonia';

@Component({
  selector: 'component-cliente-details',
  templateUrl: './cliente-details.component.html',
  styleUrls: ['./cliente-details.component.css']
})
export class ClienteDetailsComponent implements OnInit {

  @Input() clienteId: number;
  @Input() agenteId: number;
  @Input() fromModal: number;
  idCliente: string;
  cliente: cliente;
  isWorking: boolean;
  idPerfil: number = 0;
  allowModify: boolean = true;

  estados: Array<estado>;

  fileUrl: string;

  private uploader: FileUploader;

  searchColoniaCtrl = new FormControl();
  filteredColonias: any[];
  isLoading = false;
  writed = false;

  constructor(public route: ActivatedRoute, 
    public clientesService: ClientesService, 
    public estadosService: EstadosService,
    public GeoNamesService: GeonamesService,
    public agenteService: AgentesService,
    public modal: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router, @Inject('BASE_URL') base_url: string,
      public authService: AuthService, @Inject('FILE_URL') file_url: string) { 

        this.idPerfil = this.authService.idprofile;
        this.allowModify = this.idPerfil != 3;
        if(!this.allowModify){
          this.searchColoniaCtrl.disable();
        }
      
        this.idCliente = this.route.snapshot.paramMap.get('id') != null ? this.route.snapshot.paramMap.get('id') : '0';
        this.cliente = new cliente();
        this.cliente.creadoPor = new usuario();
        this.cliente.agente = new agente();
        this.estados = new Array<estado>();
        this.isWorking = false;

        this.fileUrl = file_url;

        this.uploader = new FileUploader({
          url: base_url.concat('/upload?folder=clientes'),
          disableMultipart: false,
          authToken: this.authService.getToken(),
          allowedMimeType: ['image/jpg', 'image/png', 'image/jpeg'],
          itemAlias: 'file',
          isHTML5: true
        });
    
        this.uploader.onErrorItem = (item, response, status, headers) => {
          console.error("FILE UPLOAD ERROR", response);
        };
    
        this.uploader.onSuccessItem = (item, response, status, headers) => {
          console.log('FILE UPLOADED', response);
        };
  }

  ngOnInit(): void {
    this.searchColoniaCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredColonias = [];
          this.isLoading = true;
        }),
        switchMap(value => this.GeoNamesService.get(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        console.log('COLONIA SEARCH', data);
        if (data['postalCodes'] == undefined) {
          //this.errorMsg = data['Error'];
          this.filteredColonias = [];
        } else {
          //this.errorMsg = "";
          this.filteredColonias = data['postalCodes'];
        }
 
        console.log(this.filteredColonias);
      });

    console.log('CLIENTE ID', this.clienteId);
    if(this.clienteId != null){
      this.idCliente = this.clienteId.toString();
    }

    if(this.agenteId != null){
      this.getAgente();
    }

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }

    if(this.idCliente != '0'){
      this.getCliente(this.idCliente);
    }

    this.getEstados();
  }

  selectColonia(colonia){
    console.log('SELECTED COLONIA', colonia);
    
    this.cliente.municipio = colonia.adminName2.toUpperCase();
    var estado = this.estados.filter((val, idx) => {
      return val.nombre.toLowerCase() == colonia.adminName1.toLowerCase();
    });

    if(estado != null && estado.length > 0){
      this.cliente.estado = estado[0];
      this.cliente.estadoId = estado[0].id;
    }

    console.log('ESTADO AUTOMATIC', estado);
    this.writed = false;
  }

  getAgente(){
    this.agenteService.getById(this.agenteId).subscribe(resp => {
      console.log('GET AGENTE', resp);
      if(resp.status){
        this.cliente.agente = resp.data;
        this.cliente.agenteId = resp.data.id;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING AGENTE', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'warning');
    });
  }

  getEstados(){
    this.estadosService.get().subscribe(resp => {
      console.log('GET ESTADOS', resp);
      if(resp.status){
        this.estados = resp.data;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING ESTADOS', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  getCliente(id, fFromModal?){
    this.toggleWorking();

    this.clientesService.getById(id).subscribe(resp => {
      console.log('GET CLIENTE', resp);
      if(resp.status){
        this.cliente = resp.data;

        if(this.fromModal && fFromModal){
          fFromModal(this.cliente);
        }

        if(this.cliente.clienteDesde == null || this.cliente.clienteDesde == ""){
          this.cliente.clienteDesde = "N/A";
        }

        if(this.cliente.agente == null){
          this.cliente.agente = new agente();
          this.cliente.agenteId = 0;
        }
        if(this.cliente.creadoPor == null){
          this.cliente.creadoPor = new usuario();
          this.cliente.creadoPorId = 0;
        }
        this.cliente.foto = this.cliente.foto ? this.cliente.foto : '';
      }else{
        showNotification(resp.message, 'danger');
        this.router.navigate(['admin','clientes']);
      }
    }, err => {
      console.error('GETTING CLIENTES', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo', 'danger');
      this.router.navigate(['admin','clientes']);
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  coloniaFocusOut(colonia){
    var col = this.filteredColonias.filter((val, idx) => {
      return val['placeName'] == colonia;
    });

    if(col != null && col.length > 0){
      this.selectColonia(col[0]);
    }

    console.log('WRITEN COLONIA MATCH', col);
  }

  estadoChanged(idEstado){
    var est = this.estados.filter((val, idx) => {
      return val.id == idEstado;
    });

    if(est != null && est.length > 0){
      this.cliente.estado = est[0];
    }
  }

  save(cliente){
    console.log('CLIENTE TO SAVE', cliente);
    this.toggleWorking();

    if(this.writed){
      let col = new colonia();
      col.placeName = this.cliente.colonia;
      col.adminName2 = this.cliente.municipio;
      col.adminName1 = this.cliente.estado.nombre;
      col.active = true;
      col.postalCode = this.cliente.codigoPostal;
      
      this.GeoNamesService.save(col).subscribe(resp => {
        console.log('SAVED COLONIA', resp);
        if(resp.status){
          this.filteredColonias.push(resp.data);
          showNotification('Nueva Colonia Guardada', 'success'); 
        }
      }, err => {
        console.error('SAVING COLONIA', err);
      });
    }

    this.clientesService.save(cliente).subscribe(resp => {
      console.log('SAVE CLIENTE', resp);
      if(resp.status && this.idCliente == '0'){
        this.idCliente = resp.data;
        this.getCliente(this.idCliente, (cliente) => {
          this.activeModal.close(cliente);
        });
      }
      showNotification(resp.message, resp.status ? 'success' : 'danger');
    }, err => {
      console.error('ERROR SAVING CLIENTE', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  fileSelected(event){
    console.log('SELECTED IMAGE', this.uploader);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (ev) => { // called once readAsDataURL is completed
        this.cliente.foto = ev.target.result.toString();
        console.log('SELECTED IMAGE AFTER', this.uploader);
      }
    }else{
      this.cliente.foto = '';
    }
  }

  openAgentes(){
    const modal = this.modal.open(AgentesListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.cliente.agente = result;
        this.cliente.agenteId = result.id;
      }
    }, reject => {});
  }

}
