import { Component, OnInit, Input } from '@angular/core';
import { tramiteCliente, tramitePoliza, estatusTramite, tipoTramite } from 'app/shared/models/tramite';
import { TramitesService } from 'app/shared/services/tramites.service';
import { showNotification } from 'app/shared/factories/notifications';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { Observable } from 'rxjs';
import { distinctUntilChanged, debounceTime, map } from 'rxjs/operators';
import { TramiteDetailsComponent } from '../tramite-details/tramite-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/shared/services/auth.service';
import { ramo } from 'app/shared/models/ramo';
import { RamosService } from 'app/shared/services/ramos.service';

@Component({
  selector: 'component-tramites-poliza-list',
  templateUrl: './tramites-poliza-list.component.html',
  styleUrls: ['./tramites-poliza-list.component.css']
})
export class TramitesPolizaListComponent implements OnInit {

  @Input() modalAdd: boolean;
  @Input('poliza-id') polizaId: number;
  @Input('estatus-todos') estatusTodos: boolean = false;
  @Input('mis-tramites') misTramites: boolean = false;
  @Input('agenteId') agenteId?: number;

  tramites: Array<tramitePoliza>;
  search: string;
  ffin: Date;
  finicio: Date;
  own: boolean = false;
  porfecha: boolean = false;
  pagination: any;
  isWorking: boolean;
  allowModify: boolean;
  estatuses: Array<estatusTramite>;
  etramite: number;
  eramo: number = 0;
  etipo: number = 0;

  ramos: Array<ramo>;
  tipos: Array<tipoTramite>;
  categoriaTramiteId: number = 2;

  constructor(public tramiteService: TramitesService, 
    public authService: AuthService,
    public modal: NgbModal, 
    public activeModal: NgbActiveModal, 
    public ramoService: RamosService,
    public activatedRoute: ActivatedRoute, 
    public router: Router) { 
    this.tramites = new Array<tramitePoliza>();
    this.ramos = new Array<ramo>();
    this.tipos = new Array<tipoTramite>();
    this.etramite = 0;
    this.search = '';
    this.ffin = new Date();
    this.finicio = new Date();
    this.finicio.setDate(this.ffin.getDate() - 30);
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'id_desc',
      pageSize: 50
    };
    this.allowModify = this.authService.idagente == 0;
  }

  ngOnInit(): void {
    if(this.estatusTodos){
      this.etramite = -2;
    }

    // if(this.misTramites){
    //   this.own = true;
    // }
    this.getEstatuses();
    this.getRamos();
    this.getTiposTramite();
    this.paginate(this.pagination.page);
  }

  getRamos(){
    this.ramoService.get().subscribe(resp => {
      console.log('GET RAMOS', resp);
      if(resp.status){
        this.ramos = resp.data;
      }
    });
  }

  getTiposTramite(){
    this.tramiteService.getTipos(this.categoriaTramiteId).subscribe(resp => {
      console.log('GET Tipos TRamite', resp);
      if(resp.status){
        this.tipos = resp.data;
      }
    });
  }

  getEstatuses(){
    this.tramiteService.getEstatus().subscribe(resp => {
      console.log('GET ESTATUSES', resp);
      if(resp.status){
        this.estatuses = resp.data;
      }
    }, err => {
      console.error('GETTING ESTATUSES', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'warning');
    });
  }

  paginate(page){
    this.toggleWorking();

    this.tramiteService.getTramitesPoliza(this.search, this.etramite, this.eramo, this.etipo, this.pagination.order, page, this.finicio, this.ffin, this.polizaId, this.own, this.porfecha, this.agenteId).subscribe(resp => {
      console.log('GET TRAMITE', resp);
      if(resp.status){
        this.tramites = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
      this.toggleWorking();
    }, err => {
      console.error('GETTING TRAMITES', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
      this.toggleWorking();
    });
  }

  export(){
    if(this.finicio != null && this.ffin != null){
      this.tramiteService.exportTramitesPoliza(this.search, 
        this.etramite, 
        this.eramo, 
        this.etipo, 
        this.pagination.order, 
        this.finicio, 
        this.ffin, 
        this.polizaId, 
        this.own,
        this.porfecha
        ).subscribe(resp => {
        console.log('GET TRAMITE POLIZA', resp);
        let fileURL = window.URL.createObjectURL(resp);
        window.open(fileURL);
        this.toggleWorking();
      }, err => {
        console.error('GETTING TRAMITES POLIZA', err);
        showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
      }, () =>{
        this.toggleWorking();
      });
    }else{
      showNotification('Ingresaste las fechas en un formato incorrecto', 'danger');
      this.toggleWorking();
    }
  }

  checkDelete(tramite){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(tramite);
      }
    }, reject => {});
  }

  delete(tramite){
    this.tramiteService.delete(tramite.tramite.id).subscribe(resp => {
      console.log('DELETE TRAMITE', resp);
      if(resp.status){
        this.paginate(1);
        showNotification('Tramite eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING TRAMITE', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

  detail(id){
  if(this.modalAdd){
    const modal = this.modal.open(TramiteDetailsComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.type = 'poliza';
    modal.componentInstance.id = id;
    modal.componentInstance.entityId = this.polizaId;
    modal.result.then(result => {
      this.paginate(1);
    }, reject => {
      this.paginate(1);
    });
    }
    else{
      this.router.navigate(['./edit/', id], {relativeTo: this.activatedRoute});
    }
  }

  changedRamo(eramo){
    console.log(eramo);

    if(eramo == 2){
      this.categoriaTramiteId = 1;
    }else{
      this.categoriaTramiteId = 2;
    }
    
    this.getTiposTramite();
    this.paginate(1);
  }

}
