import { agente } from "./agente";
import { cliente } from "./cliente";
import { poliza } from "./poliza";
import { siniestro } from "./siniestro";
import { usuario } from "./usuario";

export class tramiteAgente{
    id?: number;
    agenteId?: number;
    tramiteId?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    agente: agente;
    tramite: tramite;

    constructor(){
        this.id = 0;
        this.agenteId = 0;
        this.tramiteId = 0;

        this.agente = new agente();
        this.tramite = new tramite();
    }
}

export class tramiteCliente{
    id?: number;
    clienteId?: number;
    tramiteId?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    cliente: cliente;
    tramite: tramite;

    constructor(){
        this.id = 0;
        this.clienteId = 0;
        this.tramiteId = 0;

        this.cliente = new cliente();
        this.tramite = new tramite();
    }
}

export class tramitePoliza{
    id?: number;
    polizaId?: number;
    tramiteId?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    poliza: poliza;
    tramite: tramite;

    constructor(){
        this.id = 0;
        this.polizaId = 0;
        this.tramiteId = 0;

        this.poliza = new poliza();
        this.tramite = new tramite();
    }
}

export class tramiteSiniestro{
    id?: number;
    siniestroPolizaId?: number;
    tramiteId?: number;
    montoReembolso?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    siniestroPoliza: siniestro;
    tramite: tramite;

    constructor(){
        this.id = 0;
        this.siniestroPolizaId = 0;
        this.tramiteId = 0;
        this.montoReembolso = 0;

        this.siniestroPoliza = new siniestro();
        this.tramite = new tramite();
    }
}

export class tramite{
    id?: number;
    tipoTramiteId?: number;
    filaId?: number;
    estatusTramiteId?: number;
    fechaRecibido?: Date;
    fechaEnvio?: Date;
    numeroSolicitud?: string;
    numeroTramite?: string;
    descripcion?: string;
    comentarios?: string;
    fechaRealizacion?: Date;
    creadoPorId?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    tipoTramite: tipoTramite;
    fila: fila;
    estatusTramite: estatusTramite;
    creadoPor?: usuario;

    constructor(){
        this.id = 0;
        this.tipoTramiteId = 6;
        this.filaId = null;
        this.fechaRecibido = new Date();
        this.fechaRecibido.setHours(0,0,0,0);
        this.fechaEnvio = new Date();
        this.fechaEnvio.setHours(0,0,0,0);
        this.numeroSolicitud = '';
        this.numeroTramite = '';
        this.descripcion = '';
        this.comentarios = '';
        this.fechaRealizacion = new Date();
        this.fechaRealizacion.setHours(0,0,0,0);
        this.creadoPorId = 0;

        this.tipoTramite = new tipoTramite();
        this.fila = new fila();
        this.estatusTramite = new estatusTramite();
        this.creadoPor = new usuario();
    }
}

export class tipoTramite{
    id?: number;
    nombre?: string;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    constructor(){
        this.id = 0;
        this.nombre = '';
    }
}

export class fila{
    id?: number;
    nombre?: string;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    constructor(){
        this.id = 0;
        this.nombre = '';
    }
}

export class estatusTramite{
    id?: number;
    nombre?: string;
    categoriaEstatusTramiteId?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    categoriaEstatustramite: categoriaEstatusTramite;

    constructor(){
        this.id = 0;
        this.nombre = '';
        this.categoriaEstatusTramiteId = 0;
        this.categoriaEstatustramite = new categoriaEstatusTramite();
    }
}

export class categoriaEstatusTramite{
    id?: number;
    nombre?: string;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    constructor(){
        this.id = 0;
        this.nombre = '';
    }
}