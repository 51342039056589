import { Component, OnInit, Input } from '@angular/core';
import { cliente } from 'app/shared/models/cliente';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/shared/services/clientes.service';
import { showNotification } from 'app/shared/factories/notifications';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'component-clientes-list',
  templateUrl: './clientes-list.component.html',
  styleUrls: ['./clientes-list.component.css']
})
export class ClientesListComponent implements OnInit {

  @Input() fromModal: boolean;
  @Input() agenteId: number;
  clientes: cliente[];
  search: string;
  pagination: any;
  isWorking: boolean;

  constructor(public clientesService: ClientesService, public modal: NgbModal, public activeModal: NgbActiveModal) { 

    this.clientes = [];
    this.search = '';
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'nombre',
      pageSize: 50
    };

  }

  ngOnInit(): void {
    this.paginate(this.pagination.page);
  }

  paginate(page){
    this.toggleWorking();
    
    this.clientesService.get(this.search, this.pagination.order, page, this.agenteId).subscribe(resp => {
      console.log('GET CLIENTES', resp);
      if(resp.status){
        this.clientes = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GET CLIENTES', err);
      showNotification('Ocurrió un error inesperado', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  checkDelete(cliente){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(cliente);
      }
    }, reject => {});
  }

  delete(cliente){
    this.clientesService.delete(cliente.id).subscribe(resp => {
      console.log('DELETE CLIENTE', resp);
      if(resp.status){
        this.paginate(1);
        showNotification('Cliente eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING CLIENTE', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

}
