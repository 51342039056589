import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { formato } from '../models/formato';

@Injectable({
  providedIn: 'root'
})
export class FormatosService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(search: string, page: number): Observable<any>{
    return this.http.get(this.base_url.concat('formatos?','search=', search, '&page=', page.toString()), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getById(id: number): Observable<any>{
    return this.http.get(this.base_url.concat('formatos/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(formato: formato): Observable<any>{
    return this.http.post(this.base_url.concat('formatos'), formato, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('formatos/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }
}
