import { perfil } from './perfil';

export class usuario {
    id?: number;
    perfilId?: number;
    usuario?: string;
    password?: string;
    nombre?: string;

    perfil?: perfil;

    constructor(){
        this.id = 0;
        this.password = '';
        this.perfilId = 1;
        this.nombre = '';
        this.usuario = '';
        this.perfil = new perfil();
    }
}