import { Component, OnInit, Input } from '@angular/core';
import { AgentesService } from 'app/shared/services/agentes.service';
import { agente } from 'app/shared/models/agente';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { showNotification } from 'app/shared/factories/notifications';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgentesDetailComponent } from '../agentes-detail/agentes-detail.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'component-agentes-list',
  templateUrl: './agentes-list.component.html',
  styleUrls: ['./agentes-list.component.css']
})
export class AgentesListComponent implements OnInit {

  @Input() fromModal: boolean;
  @Input() modalAdd: boolean;
  agentes: agente[];
  search: string;
  pagination: any;
  isWorking: boolean;

  constructor(public agentesService: AgentesService, public modal: NgbModal, public activeModal: NgbActiveModal,
    public activatedRoute: ActivatedRoute, public router: Router) { 

    this.agentes = [];
    this.search = '';
    this.isWorking = false;
    this.pagination = {
      page: 1,
      collectionSize: 0,
      order: 'nombre',
      pageSize: 50
    };

  }

  ngOnInit(): void {
    this.paginate(this.pagination.page);
  }

  paginate(page){
    this.toggleWorking();
    
    this.agentesService.get(this.search, this.pagination.order, page).subscribe(resp => {
      console.log('GET AGENTES', resp);
      if(resp.status){
        this.agentes = resp.data;
        this.pagination.collectionSize = resp.totalItems;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GET AGENTES', err);
      showNotification('Ocurrió un error inesperado', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  checkDelete(agente){
    const modal = this.modal.open(ModalConfirmComponent);
    modal.result.then(result => {
      if(result == true){
        this.delete(agente);
      }
    }, reject => {});
  }

  delete(agente){
    this.agentesService.delete(agente.id).subscribe(resp => {
      console.log('DELETE AGENTE', resp);
      if(resp.status){
        this.paginate(1);
        showNotification('Agente eliminado', 'success');
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('DELETING AGENTE', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  detail(id){
    if(this.modalAdd){
      const modal = this.modal.open(AgentesDetailComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
      modal.componentInstance.idAgente = id;
      modal.result.then(result => {
        if(result){
          this.paginate(1);
        }
      }, reject => {
        this.paginate(1);
      });
      }
      else{
        this.router.navigate(['./edit/', id], {relativeTo: this.activatedRoute});
      }
    }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

}
