import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArchivosService } from 'app/shared/services/archivos.service';
import { FileUploader } from 'ng2-file-upload';
import { AuthService } from 'app/shared/services/auth.service';
import { showNotification } from 'app/shared/factories/notifications';
import { tipoArchivo } from 'app/shared/models/archivo';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface DialogData {
  id: number;
  type: string;
}

@Component({
  selector: 'app-archivos-dialog',
  templateUrl: './archivos-dialog.component.html',
  styleUrls: ['./archivos-dialog.component.css']
})
export class ArchivosDialogComponent implements OnInit {

  @Input() type: string;
  @Input() id: number;

  data: any;
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  matchArray: Array<any>;
  tipos: Array<tipoArchivo>;
  baseUrl: string;
  fileUrl: string;

  constructor(
    public dialogRef: NgbActiveModal,
    public archivosService: ArchivosService,
    public authService: AuthService,
    @Inject('BASE_URL') base_url: string,
    @Inject('FILE_URL') file_url: string) {
      this.matchArray = new Array<any>();
      this.tipos = new Array<tipoArchivo>();
      this.data = {
        id: 0,
        type: ''
      };
      this.baseUrl = base_url;
      this.fileUrl = file_url;
      this.hasBaseDropZoneOver = false;
    }

    
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.data = {
      id: this.id,
      type: this.type
    };    

    this.uploader = new FileUploader({
      url: this.baseUrl.concat('archivos?folder=archivos/' + this.type),
      disableMultipart: false,
      authToken: this.authService.getToken(),
      //allowedMimeType: ['image/jpg', 'image/png', 'image/jpeg'],
      itemAlias: 'file',
      isHTML5: true,
      
    });

    this.uploader.onErrorItem = (item, response, status, headers) => {
      console.error("FILE UPLOAD ERROR", response);
      showNotification('No se pudo subir el archivo ' + item.file.name + ' intentalo nuevamente o con un archivo más ligero', 'danger');
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      console.log('FILE UPLOADED response', response);
      console.log('FILE UPLOADED item', item);
      console.log('FILE UPLOADED status', status);
      showNotification(item.file.name.concat(' Guardado con Éxito'), 'success');
      this.dialogRef.close();
    };

    this.uploader.onBuildItemForm = (item, form) => {
      form.append('match', JSON.stringify(this.matchArray));
      form.append('entity', JSON.stringify(this.data));
    };

    this.getTipos();
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }
    console.log('DATA RECIEVED', this.data);
  }

  getTipos(){
    this.archivosService.getTipos().subscribe(resp => {
      console.log('GET TIPOS', resp);
      if(resp.status){
        this.tipos = resp.data;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING TIPOS', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet', 'danger');
    });
  }

  fileSelected(event){
    console.log('SELECTED IMAGE', event);
    console.log('AFTER SELECTED FILES', this.uploader);

    this.uploader.queue.forEach((file) => {
      file['nombreNuevo'] = file.file.name.replace(/\.[^/.]+$/, "");
      if(file['expiracion'] == undefined || file['expiracion'] == null){
        file['expiracion'] = new Date(2030, 11, 31);
      }
    });
  }

  fileOverBase(e:any):void {
    console.log('FILE OVER', e);
    this.hasBaseDropZoneOver = e;
  }

  upload(){
    console.log('FILES TO UPLOAD', this.uploader);
    this.uploader.queue.forEach((file) => {
      this.matchArray.push({ nombre: file.file.name, nombreNuevo: file['nombreNuevo'], tipoArchivo: file['tipoArchivo'], expiracion: file['expiracion']});
    });
    
    console.log(this.matchArray);

    this.uploader.uploadAll();
  }

  activateUpload(){
    let ret = true;
    
    this.uploader.queue.forEach((item) => {
      if(!item['nombreNuevo'] || item['nombreNuevo'] == '' || !item['tipoArchivo'] || item['tipoArchivo'] == 0){
        ret = false;
      }
    });

    return ret;
  }


}
