import { Component, OnInit, Input, Inject } from '@angular/core';
import { siniestro, tipoSiniestro} from 'app/shared/models/siniestro';
import { ActivatedRoute, Router } from '@angular/router';
import { SiniestrosService } from 'app/shared/services/siniestros.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/shared/services/auth.service';
import { showNotification } from 'app/shared/factories/notifications';
import { PolizasService } from 'app/shared/services/polizas.service';
import { PolizasListComponent } from 'app/components/polizas/polizas-list/polizas-list.component';
import { poliza } from 'app/shared/models/poliza';

@Component({
  selector: 'component-siniestro-details',
  templateUrl: './siniestro-details.component.html',
  styleUrls: ['./siniestro-details.component.css']
})
export class SiniestroDetailsComponent implements OnInit {

  @Input() idPoliza: string;
  @Input() idSiniestro: string;

  siniestro: siniestro;
  tipos: Array<tipoSiniestro>;
  isWorking: boolean;
  allowModify: boolean;
  

  constructor(public route: ActivatedRoute, 
    public siniestroService: SiniestrosService, 
    public polizaService: PolizasService,
    public modal: NgbModal,
    public router: Router, @Inject('BASE_URL') base_url: string,
    public activeModal: NgbActiveModal,
    public authService: AuthService) { 

    this.siniestro = new siniestro();
    this.tipos = new Array<tipoSiniestro>();

    this.isWorking = false;

    this.allowModify = this.authService.idagente == 0;
  }

  ngOnInit(): void {
    if(this.idSiniestro == undefined){
      this.idSiniestro = this.route.snapshot.paramMap.get('id');
    }

    if(this.idPoliza != undefined){
      this.getPoliza(this.idPoliza);
    }

    if(this.idSiniestro != '0' && this.idSiniestro != undefined){
      this.getSiniestro(this.idSiniestro);
    }

    this.getTipos();
  }

  getSiniestro(id){
    this.siniestroService.getById(id).subscribe(resp => {
      console.log('GET SINIESTRO', resp);
      if(resp.status){
        this.siniestro = resp.data;
        if(this.siniestro.tipoSiniestro == null){
          this.siniestro.tipoSiniestro = new tipoSiniestro();
        }
        if(this.siniestro.poliza == null){
          this.siniestro.poliza = new poliza();
        }
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING SINIESTRO', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    })
  }

  getPoliza(id){
    this.polizaService.getById(id).subscribe(resp => {
      console.log('GET POLIZA', resp);
      if(resp.status){
        this.siniestro.poliza = resp.data;
        this.siniestro.polizaId = this.siniestro.poliza.id;
      }else{
        showNotification(resp.message, 'danger');
      }
    }, err => {
      console.error('GETTING POLIZA', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    });
  }

  getTipos(){
    this.siniestroService.getTipos().subscribe(resp => {
      console.log('GET TIPO SINIESTRO', resp);
      if(resp.status){
        this.tipos = resp.data;
      }else{
        showNotification(resp.message, 'warning');
      }
    }, err => {
      console.error('GETTING TIPOS SINIESTRO', err);
      showNotification('Ocurrió un error inesperado, por favor verifica tu conexión a internet.', 'danger');
    })
  }

  save(siniestro){
    console.log('SINIESTRO TO SAVE', siniestro);
    this.toggleWorking();

    this.siniestroService.save(siniestro).subscribe(resp => {
      console.log('SAVE SINIESTRO', resp);
      if(resp.status && this.idSiniestro == '0'){
        this.idSiniestro = resp.data;
        this.getSiniestro(this.idSiniestro);
        this.activeModal.dismiss();
      }
      showNotification(resp.message, resp.status ? 'success' : 'danger');
    }, err => {
      console.error('ERROR SAVING SINIESTRO', err);
      showNotification('Ocurrió un error inesperado, por favor intentalo de nuevo.', 'danger');
      this.toggleWorking();
    }, () => {
      this.toggleWorking();
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  openPolizas(){
    const modal = this.modal.open(PolizasListComponent, {size: 'xl', centered: true, container: 'div', windowClass: 'modalpadding'});
    modal.componentInstance.fromModal = true;
    modal.result.then(result => {
      if(result){
        this.siniestro.poliza = result;
        this.siniestro.polizaId = result.id;
      }
    }, reject => {});
  }

}
