import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { InitializerProviderService } from './shared/services/initializer-provider.service';
import { InitializerProviderFactory } from './shared/factories/InitializerProviderFactory';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { AuthGuard } from './shared/services/guards/auth.guard';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from './shared/format-datepicker';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatTabsModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PublicLayoutComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    InitializerProviderService,
    // { provide: 'BASE_URL', useValue: 'https://sistema.lufeco.com/api/' },
    // { provide: 'FILE_URL', useValue: 'https://sistema.lufeco.com/api/file/download?file='},
    { provide: 'BASE_URL', useValue: './api/' },
    { provide: 'FILE_URL', useValue: './api/file?file='},
    // { provide: 'BASE_URL', useValue: 'https://localhost:5001/api/' },
    // { provide: 'FILE_URL', useValue: 'https://localhost:5001/api/file?file='},
    { provide: APP_INITIALIZER, useFactory: InitializerProviderFactory, deps: [InitializerProviderService], multi: true},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    //{provide: DateAdapter, useClass: AppDateAdapter}
    //{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
