import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[ngModel][uppercase]'
})
export class UppercaseDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  @HostListener('input', ['$event']) onInputChange($event) {
    $event.target.value = $event.target.value.toUpperCase();
    // console.log(this.ngModelChange);
    // this.ngModelChange.emit(this.value);
    console.log("UPPERCASE POWER!!!!!");
  }
}