import { Injectable, Inject } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(search: string, order: number, page: number, fromModal?: boolean): Observable<any>{
    return this.http.get(this.base_url.concat('usuarios?', 'search=', search, '&order=', order.toString(), '&page=', page.toString(), '&noagente=', fromModal ? '1' : '0'), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getById(id): Observable<any>{
    return this.http.get(this.base_url.concat('usuarios/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(usuario): Observable<any>{
    return this.http.post(this.base_url.concat('usuarios'), usuario, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('usuarios/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  checkUserExists(username: string): Observable<any>{
    return this.http.get(this.base_url.concat('usuarios/userexists/', username), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }
}
