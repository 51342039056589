import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { solicitudesResumen, pagadoAlDia } from 'app/shared/models/dashboard';

@Component({
  selector: 'component-solicitudes-resumen',
  templateUrl: './solicitudes-resumen.component.html',
  styleUrls: ['./solicitudes-resumen.component.css']
})
export class SolicitudesResumenComponent implements OnInit {

  @Input("mes") mes: string;
  @Input("solicitudes-gm") solicitudesGM: solicitudesResumen;
  @Input("solicitudes-vida") solicitudesVida: solicitudesResumen;
  @Input("pagado-gm") pagosGM: pagadoAlDia;
  @Input("pagado-vida") pagosVida: pagadoAlDia;

  constructor() { 
    this.solicitudesGM = new solicitudesResumen();
    this.solicitudesVida = new solicitudesResumen();
    this.pagosGM = new pagadoAlDia();
    this.pagosVida = new pagadoAlDia();
    this.mes = '';
  }
 
  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    this.solicitudesGM = changes.solicitudesGM.currentValue;
    this.solicitudesVida = changes.solicitudesVida.currentValue;
    this.pagosGM = changes.pagosGM.currentValue;
    this.pagosVida = changes.pagosVida.currentValue;

    console.log("SOL GM", this.solicitudesGM);
    console.log("SOL VIDA", this.solicitudesVida);
    console.log("PAGADO GM", this.pagosGM);
    console.log("PAGADO VIDA", this.pagosVida);
  }

}
