import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresCambioService {

  private dolar: number;

  constructor(private http: HttpClient) {
    this.dolar = 158;
  }

  getCambioDolarByDate(finicio: Date, ffin: Date): Observable<any>{
    return this.http.get(
      'https://sidofqa.segob.gob.mx/dof/sidof/indicadores/'
      .concat(
        this.dolar.toString(), '/', 
        this.formatDate(finicio), '/', 
        this.formatDate(ffin)));
  }

  private formatDate(date: Date) {
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('-');
  }

  private padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
}

export interface responseIndicador{
  messageCode: number;
  response: string;
  ListaIndicadores: Array<indicador>;
  TotalIndicadores: number;
}

export interface indicador{
  codIndicador: number;
  codTipoIndicador: number;
  fecha: string;
  valor: number;
}

