import { poliza } from "./poliza";
import { usuario } from "./usuario";

export class siniestro{
    id?: number;
    tipoSiniestroId?: number;
    polizaId?: number;
    fecha?: Date;
    descripcion: string;
    claveSiniestro: string;
    creadoPorId?: number;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    tipoSiniestro: tipoSiniestro;
    poliza: poliza;
    creadoPor?: usuario;

    constructor(){
        this.id = 0;
        this.tipoSiniestroId = 0;
        this.polizaId = 0;
        this.fecha = new Date();
        this.fecha.setHours(0,0,0,0);
        this.descripcion = '';
        this.claveSiniestro = '';

        this.tipoSiniestro = new tipoSiniestro();
        this.poliza = new poliza();
        this.creadoPor = new usuario();
    }
}

export class tipoSiniestro{
    id?: number;
    nombre?: string;

    createdAt?: Date;
    updatedAt?: Date;
    active?: boolean;

    constructor(){
        this.id = 0;
        this.nombre = '';
    }
}