import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { ResponseType, ResponseContentType } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArchivosService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }
  
  download(id: string): Observable<{filename: string, data: Blob}> {
    return this.http.get(this.base_url.concat('file/download?file=',id), { 
      observe: 'response', 
      responseType: 'blob' 
    }).pipe(
      map(response => {
        console.log(response);
        // Try to extract filename from Content-Disposition header
        // const contentDisposition = response.headers.get('Content-Disposition') || '';
        // const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        // const filename = (matches && matches[1]) ? matches[1] : "default_filename";
        const filename = response.headers.get('X-Filename');
        console.log(filename);
        return { filename, data: response.body };
      })
    );
  }

  // download(id: string): Observable<any>{
  //   return this.http.get(this.base_url.concat('file/download?file=',id), {
  //     responseType:  'blob',
  //     headers: {
  //         'Authorization': this.authService.getToken(),
  //     }
  //   });
  // }

  get(id: number, type: string): Observable<any>{
    return this.http.get(this.base_url.concat('archivos?id=',id.toString(),'&type=',type), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('archivos/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getTipos(): Observable<any>{
    return this.http.get(this.base_url.concat('archivos/tipos'), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }

  migrate(): Observable<any>{
    return this.http.get(this.base_url.concat('file/migrate?take=200'), {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': this.authService.getToken()
      }
    });
  }
}
