import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiniestrosService {

  base_url : string;

  constructor(public http: HttpClient, public authService: AuthService, @Inject('BASE_URL') baseUrl: string) { 
    this.base_url = baseUrl;
  }

  get(search: string, order: number, page: number, polizaId?: number): Observable<any>{
    return this.http.get(this.base_url.concat('siniestros?', 'search=', search, '&order=', order.toString(), '&page=', page.toString(), '&polizaId=', polizaId != undefined ? polizaId.toString() : '0'), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': this.authService.getToken()
        }
      });
  }

  getTipos(): Observable<any>{
    return this.http.get(this.base_url.concat('tiposSiniestro'), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  getById(id): Observable<any>{
    return this.http.get(this.base_url.concat('siniestros/', id), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  save(siniestro): Observable<any>{
    siniestro.polizaId = siniestro.polizaId != 0 ? siniestro.polizaId : null;
    siniestro.tipoSiniestroId = siniestro.tipoSiniestroId != 0 ? siniestro.tipoSiniestroId : null;
    return this.http.post(this.base_url.concat('siniestros'), siniestro, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }

  delete(id: number): Observable<any>{
    return this.http.delete(this.base_url.concat('siniestros/', id.toString()), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.authService.getToken()
      }
    });
  }
}
