import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormatosService } from 'app/shared/services/formatos.service';
import { AuthService } from 'app/shared/services/auth.service';
import { formato } from 'app/shared/models/formato';
import { showNotification } from 'app/shared/factories/notifications';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatosDetailComponent } from '../formatos-detail/formatos-detail.component';
import { ModalConfirmComponent } from 'app/components/modals/modals.component';
import { ArchivosService } from 'app/shared/services/archivos.service';

@Component({
  selector: 'component-formatos-list',
  templateUrl: './formatos-list.component.html',
  styleUrls: ['./formatos-list.component.css']
})
export class FormatosListComponent implements OnInit {

  @Input() allowEdit: boolean = true;
  formatos: Array<formato>;
  pagination = {
    page: 1,
    collectionSize: 0,
    order: 'id_desc',
    pageSize: 3
  };
  search: string = '';
  isWorking: boolean = false;
  fileUrl: string;

  constructor(private formatosService: FormatosService, 
    private authService: AuthService, 
    private modal: NgbModal,
    private archivoService: ArchivosService,
    @Inject('FILE_URL') file_url: string) { 
      this.formatos = new Array<formato>();
      this.fileUrl = file_url;
  }

  ngOnInit(): void {
    this.paginate(this.pagination.page);
  }

  paginate(page){
    this.isWorking = true;

    this.formatosService.get(this.search, page).subscribe(resp => {
      console.log("GETTING FORMATOS", resp);
      if(resp.status){
        this.formatos = resp.data;
        this.formatos = this.formatos.map(f => {
          f['downloading'] = false;
          return f;
        });
        this.pagination.collectionSize = resp.totalItems;
      }

      this.isWorking = false;
    }, err => {
      console.error("GETTING FORMATOS", err);
      showNotification('Ocurrió un error inesperado, verifica tu conexión a internet.', 'danger');
      this.isWorking = false;
    });
  }

  toggleWorking(){
    this.isWorking = !this.isWorking;
  }

  searchBox = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.paginate(1))
    )

  detail(id){
    const modal = this.modal.open(FormatosDetailComponent, {size: 'md', centered: true, container: 'div', windowClass: 'p-5'});
    modal.componentInstance.idFormato = id;
    modal.result.then(result => {
        if(result){
          this.paginate(1);
        }
      }, reject => {
        this.paginate(1);
      });
    }

    checkDelete(formato: formato){
      const modal = this.modal.open(ModalConfirmComponent);
      modal.result.then(result => {
        if(result == true){
          this.delete(formato);
        }
      }, reject => {});
    }
  
    delete(formato: formato) {
      this.formatosService.delete(formato.id).subscribe(resp => {
        console.log('DELETING FORMATO', resp);
        if(resp.status){
          this.paginate(1);
        }else{
          showNotification(resp.message, 'danger');
        }
      }, err => {
        console.error('DELETING FORMATO ERROR', err);
        showNotification('Ocurrió un error inesperado, verifica tu conexión a internet.', 'danger');
      });
    }

    download(file){
      console.log(file.url);
      file['downloading'] = true;
      this.archivoService.download(file.url).subscribe({
        next: ({ filename, data }) => {
          // Create a URL for the blob
          const fileURL = window.URL.createObjectURL(data);
          // Create an anchor tag to download the blob with the extracted filename
          const a = document.createElement('a');
          document.body.appendChild(a); // Required for Firefox
          a.style.display = 'none';
          a.href = fileURL;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(fileURL);
          a.remove();
          file['downloading'] = false;
        }, 
        error: () =>{
          error => console.error('Download error:', error);
          showNotification('No se pudo descargar el archivo, es posible que este archivo no exista', 'error');
          file['downloading'] = false;
        } 
      });
    }
}
