import { Component, OnInit } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthService } from 'app/shared/services/auth.service';
import { Title } from '@angular/platform-browser';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    children?: RouteInfo[]
}
export const ROUTES_ADMIN: RouteInfo[] = [
    { path: '/admin/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
    { path: '/admin/usuarios', title: 'Usuarios', icon: 'supervisor_account', class: '' },
    { path: '/admin/agentes', title: 'Agentes', icon: 'assignment_ind', class: '' },
    { path: '/admin/clientes', title: 'Clientes', icon: 'tag_faces', class: '' },
    { path: '/admin/polizas', title: 'Polizas', icon: 'description', class: '' },
    { path: '/admin/siniestros', title: 'Siniestros', icon: 'report_problem', class: '' },
    { path: '/admin/tramites', title: 'Tramites', icon: 'inbox', class: '' },
    { path: '/admin/cumpleanos', title: 'Cumples del Mes', icon: 'cake', class: ''},
    { path: '/admin/extras', title: 'Extras', icon: 'star', class: 'dropdown', children: [
        { path: '/carga-pagos', title: 'Carga de Pagos', icon: 'attach_money', class: '' },
        { path: '/formatos', title: 'Formatos', icon: 'description', class: ''}
    ]},
    
    { path: '/public/logout', title: 'Salir', icon: 'exit_to_app', class: '' },
];

export const ROUTES_AGENT: RouteInfo[] = [
    { path: '/agente/perfil', title: 'Perfil', icon: 'perm_identity', class: ''},
    { path: '/agente/cumpleanos', title: 'Cumples del Mes', icon: 'cake', class: ''},
    { path: '/agente/extras', title: 'Extras', icon: 'star', class: 'dropdown', children: [
         { path: '/formatos', title: 'Formatos', icon: 'description', class: ''}
    ]},
    
    { path: '/public/logout', title: 'Salir', icon: 'exit_to_app', class: 'active-pro' },
];

export const ROUTES_CAPTURIST: RouteInfo[] = [
    { path: '/capturista/agentes', title: 'Agentes', icon: 'assignment_ind', class: '' },
    { path: '/capturista/clientes', title: 'Clientes', icon: 'tag_faces', class: ''},
    { path: '/capturista/polizas', title: 'Polizas', icon: 'description', class: '' },
    { path: '/capturista/siniestros', title: 'Siniestros', icon: 'report_problem', class: '' },
    { path: '/capturista/tramites', title: 'Tramites', icon: 'inbox', class: '' },
    { path: '/admin/extras', title: 'Extras', icon: 'star', class: 'dropdown', children: [
        // { path: '/carga-pagos', title: 'Carga de Pagos', icon: 'attach_money', class: '' },
        // { path: '/formatos', title: 'Formatos', icon: 'description', class: ''}
    ]},

    { path: '/public/logout', title: 'Salir', icon: 'exit_to_app', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    menuItems: any[];
    ROUTES: RouteInfo[];

    constructor(private storage: StorageMap, public authService: AuthService) {

            switch (this.authService.idprofile) {
                case 1:
                    this.ROUTES = ROUTES_ADMIN;
                    break;
                case 2:
                    this.ROUTES = ROUTES_CAPTURIST;
                    break;
                case 3:
                    this.ROUTES = ROUTES_AGENT;
                    break;
            }
    }

  ngOnInit() {
    this.menuItems = this.ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
